import { BotType } from "@/ts/business/game/BotType";
import { GameType } from "@/ts/business/game/GameType";


export class Bot {

    private readonly type: BotType;
    private readonly settings: GameType;

    constructor(type: BotType, settings: GameType) {
        this.type = type;
        this.settings = settings;
    }

    getType(): BotType {
        return this.type;
    }

    getSettings(): GameType {
        return this.settings;
    }
}
