

/**
 * A single rolled die.
 */
export class RoyalUrRolledDie {
    /**
     * Whether this die has a marked side upwards.
     */
    private readonly up: boolean;

    /**
     * A value in the range [0, 1) representing
     * the variation of dice to render.
     */
    private readonly variation: number;

    constructor(up: boolean, variation: number) {
        this.up = up;
        this.variation = variation;
    }

    /**
     * Returns whether this die has a marked side upwards.
     */
    isUp(): boolean {
        return this.up;
    }

    /**
     * Returns a value in the range [0, 1) representing
     * the variation of dice to render.
     */
    getVariation(): number {
        return this.variation;
    }
}
