import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { LobbyPlayerType } from "@/ts/business/lobby/LobbyPlayerType";
import { APIPublicGamePreferences, APIUser } from "@/ts/business/api/api_schema";
import { BotType } from "@/ts/business/game/BotType";


export class HumanLobbyPlayer extends LobbyPlayer {
    private readonly user: APIUser | null;
    private readonly preferences: APIPublicGamePreferences | null;

    constructor(
        user: APIUser | null,
        gameData: GamePlayerData | null,
        preferences: APIPublicGamePreferences | null,
    ) {
        super(LobbyPlayerType.HUMAN, gameData);
        this.user = user;
        this.preferences = preferences;
    }

    getUserOrNull(): APIUser | null {
        return this.user;
    }

    isAnonymous(): boolean {
        return this.user === null;
    }

    override getBotTypeOrNull(): BotType | null {
        return null;
    }

    override getPreferences(): APIPublicGamePreferences | null {
        return this.preferences;
    }

    override getShortName(): string {
        if (this.user === null)
            return "Anonymous";

        return this.user.displayName;
    }

    override withPreferences(preferences: APIPublicGamePreferences): LobbyPlayer {
        return new HumanLobbyPlayer(
            this.user, this.getGameData(), preferences,
        );
    }

    override withGameData(gameData: GamePlayerData): LobbyPlayer {
        return new HumanLobbyPlayer(
            this.user, gameData, this.preferences,
        );
    }

    override equals(other: LobbyPlayer): boolean {
        if (!LobbyPlayer._baseEquals(this, other))
            return false;

        return other instanceof HumanLobbyPlayer;
    }
}
