import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { readJsonString, readNullableJsonInt } from "@/ts/util/json";
import { ReactionType } from "@/ts/business/game/ReactionType";


export class PacketInReaction extends PacketIn {
    public playerNo: number | null = null;
    public reaction: ReactionType | null = null;

    constructor() {
        super(InboundPacketType.REACTION);
    }

    override readContents(_context: MessageIOContext, json: Record<string, any>): void {
        this.playerNo = readNullableJsonInt(json, "player_no");
        this.reaction = ReactionType.getByID(readJsonString(json, "reaction_id"));
    }
}
