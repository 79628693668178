

export function isJsonDict(value: Exclude<any, undefined>): value is Record<string, any> {
    if (value === null || typeof value !== "object")
        return false;
    if (Array.isArray(value))
        return false;

    for (const key in value) {
        if (typeof key !== "string")
            return false;
    }
    return true;
}


export function getJsonType(value: Exclude<any, undefined>): string {
    if (value === null)
        return "null";
    if (typeof value === "object") {
        if (Array.isArray(value))
            return "array";
        if (isJsonDict(value))
            return "dict";
        return "unknown";
    }
    return typeof value;
}


export function readNullableJsonValue(json: Record<string, any>, key: string): any {
    if (!(key in json))
        return null;

    return json[key];
}


export function readJsonValue(json: Record<string, any>, key: string): Exclude<any, undefined> {
    if (!(key in json))
        throw new Error(`Missing ${key}`);

    return json[key];
}


export function readJsonDict(json: Record<string, any>, key: string): Record<string, any> {
    const value = readJsonValue(json, key);
    if (!isJsonDict(value))
        throw new Error(`Expected ${key} to be a dictionary, not ${getJsonType(value)}`);

    return value;
}


export function readNullableJsonDict(json: Record<string, any>, key: string): Record<string, any> | null {
    const value = readNullableJsonValue(json, key);
    if (value !== null && !isJsonDict(value))
        throw new Error(`Expected ${key} to be a dictionary or null, not ${getJsonType(value)}`);

    return value;
}


export function readJsonArray(json: Record<string, any>, key: string): any[] {
    const value = readJsonValue(json, key);
    if (!Array.isArray(value))
        throw new Error(`Expected ${key} to be an array, not ${getJsonType(value)}`);

    return value;
}


export function readJsonString(json: Record<string, any>, key: string): string {
    const value = readJsonValue(json, key);
    if (typeof value !== "string")
        throw new Error(`Expected ${key} to be a string, not ${getJsonType(value)}`);

    return value;
}


export function readJsonDate(json: Record<string, any>, key: string): Date {
    return new Date(readJsonString(json, key));
}


export function readNullableJsonDate(json: Record<string, any>, key: string): Date | null {
    const value = readNullableJsonString(json, key);
    return (value !== null ? new Date(value) : null);
}


export function readJsonChar(json: Record<string, any>, key: string): string {
    const value = readJsonString(json, key);
    if (value.length !== 1)
        throw new Error(`Expected ${key} to contain a single character, not ${value.length} characters`);

    return value;
}


export function readNullableJsonString(json: Record<string, any>, key: string): string | null {
    const value = readNullableJsonValue(json, key);
    if (value !== null && typeof value !== "string")
        throw new Error(`Expected ${key} to be a string or null, not ${getJsonType(value)}`);

    return value;
}


export function readNullableJsonChar(json: Record<string, any>, key: string): string | null {
    const value = readNullableJsonString(json, key);
    if (value !== null && value.length !== 1)
        throw new Error(`Expected ${key} to contain a single character or null, not ${value.length} characters`);

    return value;
}


export function readJsonNumber(json: Record<string, any>, key: string): number {
    const value = readJsonValue(json, key);
    if (typeof value !== "number")
        throw new Error(`Expected ${key} to be a number, not ${getJsonType(value)}`);

    return value;
}


export function readNullableJsonNumber(json: Record<string, any>, key: string): number | null {
    const value = readNullableJsonValue(json, key);
    if (value !== null && typeof value !== "number")
        throw new Error(`Expected ${key} to be a number or null, but was ${value}`);

    return value;
}


export function readJsonInt(json: Record<string, any>, key: string): number {
    const value = readJsonNumber(json, key);
    if (!Number.isInteger(value))
        throw new Error(`Expected ${key} to be an integer, but was ${value}`);

    return value;
}


export function readNullableJsonInt(json: Record<string, any>, key: string): number | null {
    const value = readNullableJsonValue(json, key);
    if (value !== null && !Number.isInteger(value))
        throw new Error(`Expected ${key} to be an integer or null, but was ${value}`);

    return value;
}


export function readJsonBool(json: Record<string, any>, key: string): boolean {
    const value = readJsonValue(json, key);
    if (typeof value !== "boolean")
        throw new Error(`Expected ${key} to be a boolean, not ${getJsonType(value)}`);

    return value;
}


export function readNullableJsonBool(json: Record<string, any>, key: string): boolean | null {
    const value = readNullableJsonValue(json, key);
    if (value !== null && typeof value !== "boolean")
        throw new Error(`Expected ${key} to be a boolean or null, not ${getJsonType(value)}`);

    return value;
}


export function readJsonEnum<V>(
    json: Record<string, string>,
    key: string,
    parsingMap: Record<string, V>,
): V {
    const id = readJsonString(json, key);
    const value = parsingMap[id];
    if (value === undefined)
        throw new Error(`Unknown enum ID ${id}`);

    return value;
}


export function readNullableJsonEnum<V>(
    json: Record<string, string>,
    key: string,
    parsingMap: Record<string, V>,
): V | null {
    const id = readNullableJsonString(json, key);
    if (id === null)
        return null;

    const value = parsingMap[id];
    return value !== undefined ? value : null;
}


export function readJsonEnumOrElse<V>(
    json: Record<string, string>,
    key: string,
    parsingMap: Record<string, V>,
    defaultValue: V,
): V {
    const id = readNullableJsonString(json, key);
    if (id === null)
        return defaultValue;

    const value = parsingMap[id];
    return value !== undefined ? value : defaultValue;
}


export function writeNullableJsonValue<V>(
    key: string, value: V | null,
): Record<string, never> | { [key: string]: V } {

    return value !== null ? { [key]: value } : {};
}
