import { JsonNotation } from "@/ts/royalur/notation/JsonNotation";
import { GamePlayerDataNotation } from "@/ts/business/api/game/GamePlayerDataNotation";
import { readJsonString } from "@/ts/util/json";
import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { buildPacketIn } from "@/ts/business/game/server/inbound/buildPacketIn";
import { LobbyNotation } from "@/ts/business/lobby/LobbyNotation";
import { RoyalUrJsonNotation } from "@/ts/business/game/royalur/RoyalUrJsonNotation";


export class MessageIOContext {
    private readonly lobbyNotation: LobbyNotation;
    private readonly gameNotation: JsonNotation;
    private readonly playerDataNotation: GamePlayerDataNotation;

    constructor(
        lobbyNotation: LobbyNotation,
        gameNotation: JsonNotation,
        playerDataNotation: GamePlayerDataNotation,
    ) {
        this.lobbyNotation = lobbyNotation;
        this.gameNotation = gameNotation;
        this.playerDataNotation = playerDataNotation;
    }

    static createDefault() {
        const playerDataNotation = new GamePlayerDataNotation();
        return new MessageIOContext(
            new LobbyNotation(playerDataNotation),
            new RoyalUrJsonNotation(),
            playerDataNotation,
        );
    }

    getLobbyNotation(): LobbyNotation {
        return this.lobbyNotation;
    }

    getGameNotation(): JsonNotation {
        return this.gameNotation;
    }

    getPlayerDataNotation(): GamePlayerDataNotation {
        return this.playerDataNotation;
    }

    readPacket(json: Record<string, any>) {
        const typeName = readJsonString(json, PacketIn.PACKET_TYPE_KEY);
        const type = InboundPacketType.getByName(typeName);
        const packet = buildPacketIn(type);
        packet.read(this, json);
        return packet;
    }
}
