import { z } from "zod";


export const APIBestMove = z.object({
    desc: z.string(),
    move: z.record(z.string(), z.any()),
    chosen_move_lost_wp: z.number(),
});
export type APIBestMove = z.infer<typeof APIBestMove>;


export const APIMoveAnalysis = z.object({
    is_player_light: z.boolean(),
    category: z.nullable(z.string()),
    summary: z.string(),
    before_lwp: z.number(),
    after_lwp: z.number(),
    roll_desc: z.nullable(z.string()),
    move_desc: z.nullable(z.string()),
    best_move: z.nullable(APIBestMove),
    is_key_move: z.boolean(),
    debug_desc: z.nullable(z.string()),
    debug_variables: z.nullable(z.record(z.string(), z.number())),
    debug_substitutions: z.nullable(z.record(z.string(), z.string())),
});
export type APIMoveAnalysis = z.infer<typeof APIMoveAnalysis>;
