import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { readJsonNumber } from "@/ts/util/json";


export class PacketInPong extends PacketIn {
    public clientTimeMs: number | null = null;
    public serverTimeMs: number | null = null;

    constructor() {
        super(InboundPacketType.PONG);
    }

    override readContents(_context: MessageIOContext, json: Record<string, any>): void {
        this.clientTimeMs = readJsonNumber(json, "client_time_ms");
        this.serverTimeMs = readJsonNumber(json, "server_time_ms");
    }
}
