

export class LobbyPlayerType {
    public static readonly UNKNOWN = new LobbyPlayerType("unknown");
    public static readonly HUMAN = new LobbyPlayerType("human");
    public static readonly BOT = new LobbyPlayerType("bot");

    static values(): LobbyPlayerType[] {
        return [
            LobbyPlayerType.UNKNOWN,
            LobbyPlayerType.HUMAN,
            LobbyPlayerType.BOT,
        ];
    }

    private readonly id: string;

    constructor(id: string) {
        this.id = id;
    }

    getID(): string {
        return this.id;
    }

    static getByID(id: string) {
        const upperID = id.toUpperCase();
        for (const playerType of LobbyPlayerType.values()) {
            if (playerType.getID().toUpperCase() === upperID)
                return playerType;
        }
        throw new Error("Unknown lobby player type " + upperID);
    }
}
