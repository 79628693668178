import { z } from "zod";
import { APIMoveAnalysis } from "@/ts/business/api/analysis/APIMoveAnalysis";


export const APIPlayerAnalysis = z.object({
    move_count: z.number(),
    mean_roll_delta: z.number(),
    mean_move_delta: z.number(),
});
export type APIPlayerAnalysis = z.infer<typeof APIPlayerAnalysis>;


export const APIMoveCategoryCounts = z.object({
    light: z.record(z.string(), z.number()),
    dark: z.record(z.string(), z.number()),
});


export const APIGameAnalysis = z.object({
    light_start_summary: z.string(),
    dark_start_summary: z.string(),
    light_end_summary: z.string(),
    dark_end_summary: z.string(),
    debug_overview: z.nullable(z.string()),
    light: APIPlayerAnalysis,
    dark: APIPlayerAnalysis,
    category_counts: z.nullable(APIMoveCategoryCounts),
    moves: z.array(APIMoveAnalysis),
    move_lwps: z.array(z.number()),
    has_been_limited: z.boolean(),
    is_bare_minimum: z.boolean(),
});
export type APIGameAnalysis = z.infer<typeof APIGameAnalysis>;
