import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { LobbyPlayerType } from "@/ts/business/lobby/LobbyPlayerType";
import { BotType } from "@/ts/business/game/BotType";
import { APIPublicGamePreferences, APIUser } from "@/ts/business/api/api_schema";


export class BotLobbyPlayer extends LobbyPlayer {
    private readonly botType: BotType;

    constructor(botType: BotType, gameData: GamePlayerData | null) {
        super(LobbyPlayerType.BOT, gameData);
        this.botType = botType;
    }

    getBotType(): BotType {
        return this.botType;
    }

    override getBotTypeOrNull(): BotType | null {
        return this.botType;
    }

    override getUserOrNull(): APIUser | null {
        return null;
    }

    override isAnonymous(): boolean {
        return false;
    }

    override getShortName(): string {
        return this.botType.getName();
    }

    override getLongName(): string {
        return this.botType.getLongName();
    }

    override withPreferences(_preferences: APIPublicGamePreferences): LobbyPlayer {
        // Bot players don't have preferences.
        return this;
    }

    override withGameData(gameData: GamePlayerData): LobbyPlayer {
        return new BotLobbyPlayer(
            this.botType, gameData,
        );
    }

    override equals(other: LobbyPlayer): boolean {
        if (!LobbyPlayer._baseEquals(this, other))
            return false;
        if (!(other instanceof BotLobbyPlayer))
            return false;

        return this.botType === other.botType;
    }
}
