import { Roll } from "@/ts/royalur/model/dice/Roll";
import { Move } from "@/ts/royalur/model/Move";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { ResignedGameState } from "@/ts/royalur/rules/state/ResignedGameState";
import { AbandonedGameState } from "@/ts/royalur/rules/state/AbandonedGameState";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";


/**
 * Produces game states from serialised information.
 */
export abstract class StateSource {
    abstract createRolledState(
        rules: RuleSet, turn: PlayerType, roll: Roll,
    ): RolledGameState;

    abstract createMovedState(
        rules: RuleSet, turn: PlayerType, roll: Roll, move: Move,
    ): MovedGameState;

    abstract createWaitingForRollState(
        rules: RuleSet, turn: PlayerType,
    ): WaitingForRollGameState;

    abstract createWaitingForMoveState(
        rules: RuleSet, turn: PlayerType, roll: Roll,
    ): WaitingForMoveGameState;

    abstract createResignedState(
        rules: RuleSet, player: PlayerType,
    ): ResignedGameState;

    abstract createAbandonedState(
        rules: RuleSet, reason: AbandonReason, player: PlayerType | null,
    ): AbandonedGameState;

    abstract createEndState(
        rules: RuleSet, winner: PlayerType | null,
    ): EndGameState;
}
