import { LobbyPlayerType } from "@/ts/business/lobby/LobbyPlayerType";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { APIPublicGamePreferences, APIUser, defaultGamePreferences } from "@/ts/business/api/api_schema";
import { BotType } from "@/ts/business/game/BotType";


export abstract class LobbyPlayer {
    private readonly type: LobbyPlayerType;
    private readonly gameData: GamePlayerData | null;

    protected constructor(type: LobbyPlayerType, gameData: GamePlayerData | null) {
        this.type = type;
        this.gameData = gameData;
    }

    getType(): LobbyPlayerType {
        return this.type;
    }

    getGameData(): GamePlayerData | null {
        return this.gameData;
    }

    getPlayerType(): PlayerType | null {
        if (this.gameData === null)
            return null;
        return this.gameData.getPlayerType();
    }

    abstract getBotTypeOrNull(): BotType | null;

    abstract getUserOrNull(): APIUser | null;

    abstract isAnonymous(): boolean;

    getPreferences(): APIPublicGamePreferences | null {
        return defaultGamePreferences;
    }

    abstract getShortName(): string;

    getLongName(): string {
        return this.getShortName();
    }

    abstract withPreferences(preferences: APIPublicGamePreferences): LobbyPlayer;

    abstract withGameData(gameData: GamePlayerData): LobbyPlayer;

    abstract equals(other: LobbyPlayer): boolean;

    static _baseEquals(player1: LobbyPlayer, player2: LobbyPlayer): boolean {
        if (player1.type !== player2.type)
            return false;
        if (player1.gameData === null)
            return player2.gameData === null;

        return player1.gameData.equals(player2.gameData);
    }
}
