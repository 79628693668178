
/*
 * Used to simplify the presentation of moves by grouping them.
 */
export class MoveCategory {
    /**
     * The move won the game.
     */
    public static readonly WIN = new MoveCategory("win", "Win", true, true);

    /**
     * There was only one option.
     */
    public static readonly FORCED = new MoveCategory("forced", "Forced", true, true);

    /**
     * The move was best and was hard to see.
     */
    public static readonly BRILLIANT = new MoveCategory("brilliant", "Brilliant", true, false);

    /**
     * The move was best and was impactful.
     */
    public static readonly EXCELLENT = new MoveCategory("excellent", "Excellent", true, false);

    /**
     * The move was best.
     */
    public static readonly BEST = new MoveCategory("best", "Best", true, false);

    /**
     * The move was not the best, but it was really close.
     */
    public static readonly GREAT = new MoveCategory("great", "Great", false, false);

    /**
     * The move was not the best, but was still good.
     */
    public static readonly GOOD = new MoveCategory("good", "Good", false, false);

    /**
     * The move lost a decent amount of win percentage.
     */
    public static readonly MISTAKE = new MoveCategory("mistake", "Mistake", false, false);

    /**
     * The move lost a lot of win percentage.
     */
    public static readonly BLUNDER = new MoveCategory("blunder", "Blunder", false, false);

    static values(): MoveCategory[] {
        return [
            MoveCategory.WIN,
            MoveCategory.FORCED,
            MoveCategory.BRILLIANT,
            MoveCategory.EXCELLENT,
            MoveCategory.BEST,
            MoveCategory.GREAT,
            MoveCategory.GOOD,
            MoveCategory.MISTAKE,
            MoveCategory.BLUNDER,
        ];
    }

    readonly id: string;
    readonly name: string;
    readonly isBest: boolean;
    readonly isForced: boolean;

    constructor(id: string, name: string, isBest: boolean, isForced: boolean) {
        this.id = id;
        this.name = name;
        this.isBest = isBest;
        this.isForced = isForced;
    }

    static getByID(id: string): MoveCategory {
        for (const category of MoveCategory.values()) {
            if (category.id === id)
                return category;
        }
        throw new Error(`Unknown move category ${id}`);
    }

    static getByIDOrNull(id: string | null): MoveCategory | null {
        if (id === null)
            return null;

        for (const category of MoveCategory.values()) {
            if (category.id === id)
                return category;
        }
        return null;
    }
}
