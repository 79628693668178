import { MessageIn } from "@/ts/business/api/MessageIn";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { readJsonDict, readJsonString } from "@/ts/util/json";
import { APIGameAnalysis } from "@/ts/business/api/analysis/APIGameAnalysis";


export class MessageInAnalysedGame extends MessageIn {
    public gameID: string | null = null;
    public analysis: APIGameAnalysis | null = null;

    read(_context: MessageIOContext, json: Record<string, any>): void {
        this.gameID = readJsonString(json, "game_id");
        this.analysis = APIGameAnalysis.parse(readJsonDict(json, "analysis"));
    }
}
