import { Move } from "@/ts/royalur/model/Move";
import { MoveCategory } from "@/ts/business/analysis/MoveCategory";
import { APIBestMove, APIMoveAnalysis } from "@/ts/business/api/analysis/APIMoveAnalysis";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


export class BestMove {
    readonly desc: string;
    readonly move: Move;
    readonly chosenMoveLostWP: number;

    constructor(desc: string, move: Move, chosenMoveLostWP: number) {
        this.desc = desc;
        this.move = move;
        this.chosenMoveLostWP = chosenMoveLostWP;
    }

    static create(
        rules: RuleSet,
        context: MessageIOContext,
        apiBestMove: APIBestMove,
    ): BestMove {
        const move = context.getGameNotation().readMove(
            rules, apiBestMove.move
        );
        return new BestMove(
            apiBestMove.desc,
            move,
            apiBestMove.chosen_move_lost_wp,
        );
    }
}


export class MoveAnalysis {
    public readonly player: PlayerType;
    public readonly category: MoveCategory | null;
    public readonly summary: string;
    public readonly beforeLWP: number;
    public readonly afterLWP: number;
    public readonly rollDesc: string | null;
    public readonly moveDesc: string | null;
    public readonly bestMove: BestMove | null;
    public readonly isKeyMove: boolean;
    public readonly debugDesc: string | null;
    public readonly debugVariables: Record<string, number> | null;
    public readonly debugSubstitutions: Record<string, string> | null;

    constructor(
        player: PlayerType,
        category: MoveCategory | null,
        summary: string,
        beforeLWP: number,
        afterLWP: number,
        rollDesc: string | null,
        moveDesc: string | null,
        bestMove: BestMove | null,
        isKeyMove: boolean,
        debugDesc: string | null,
        debugVariables: Record<string, number> | null,
        debugSubstitutions: Record<string, string> | null,
    ) {
        this.player = player;
        this.category = category;
        this.summary = summary;
        this.beforeLWP = beforeLWP;
        this.afterLWP = afterLWP;
        this.rollDesc = rollDesc;
        this.moveDesc = moveDesc;
        this.bestMove = bestMove;
        this.isKeyMove = isKeyMove;
        this.debugDesc = debugDesc;
        this.debugVariables = debugVariables;
        this.debugSubstitutions = debugSubstitutions;
    }

    withoutMoveDesc(): MoveAnalysis {
        return new MoveAnalysis(
            this.player,
            this.category,
            this.summary,
            this.beforeLWP,
            this.afterLWP,
            this.rollDesc,
            "",
            this.bestMove,
            this.isKeyMove,
            this.debugDesc,
            this.debugVariables,
            this.debugSubstitutions,
        );
    }

    getBeforeWP(player: PlayerType): number {
        return (player === PlayerType.LIGHT ? this.beforeLWP : 100 - this.beforeLWP);
    }

    getAfterWP(player: PlayerType): number {
        return (player === PlayerType.LIGHT ? this.afterLWP : 100 - this.afterLWP);
    }

    getWPDelta(): number {
        return this.getAfterWP(this.player) - this.getBeforeWP(this.player);
    }

    static create(
        rules: RuleSet,
        context: MessageIOContext,
        apiMoveAnalysis: APIMoveAnalysis,
    ): MoveAnalysis {
        const player = (apiMoveAnalysis.is_player_light ? PlayerType.LIGHT : PlayerType.DARK);
        const category = (
            apiMoveAnalysis.category
                ? MoveCategory.getByID(apiMoveAnalysis.category)
                : null
        );
        const bestMove = (
            apiMoveAnalysis.best_move
                ? BestMove.create(rules, context, apiMoveAnalysis.best_move)
                : null
        );
        return new MoveAnalysis(
            player,
            category,
            apiMoveAnalysis.summary,
            apiMoveAnalysis.before_lwp,
            apiMoveAnalysis.after_lwp,
            apiMoveAnalysis.roll_desc,
            apiMoveAnalysis.move_desc,
            bestMove,
            apiMoveAnalysis.is_key_move,
            apiMoveAnalysis.debug_desc,
            apiMoveAnalysis.debug_variables,
            apiMoveAnalysis.debug_substitutions,
        );
    }
}
