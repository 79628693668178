import { Roll } from "@/ts/royalur/model/dice/Roll";
import { RoyalUrRolledDie } from "@/ts/business/game/royalur/RoyalUrRolledDie";


/**
 * A roll of the RoyalUr.net dice.
 */
export class RoyalUrRoll implements Roll {
    private readonly rollValue: number;
    private readonly dies: RoyalUrRolledDie[];

    constructor(value: number, dies: RoyalUrRolledDie[]) {
        this.rollValue = value;
        this.dies = dies;
    }

    getNumDie(): number {
        return this.dies.length;
    }

    getDie(): RoyalUrRolledDie[] {
        return this.dies;
    }

    value(): number {
        return this.rollValue;
    }

    equals(other: any): boolean {
        if (!other || this.constructor !== other.constructor)
            return false;
        if (!(other instanceof RoyalUrRoll))
            throw new Error("Same constructor, but instanceof returns false");

        return this.value === other.value && this.dies.length === other.dies.length;
    }

    static cast(roll: Roll): RoyalUrRoll {
        if (!(roll instanceof RoyalUrRoll))
            throw new Error("Roll is not a RoyalUrRoll!");

        return roll;
    }

    static createZeroed(numDie: number): RoyalUrRoll {
        const dies: RoyalUrRolledDie[] = [];
        const die = new RoyalUrRolledDie(false, 0);
        for (let index = 0; index < numDie; ++index) {
            dies.push(die);
        }
        return new RoyalUrRoll(0, dies);
    }
}
