

export type PlayerNo = 1 | 2;


export function castPlayerNo(playerNo: number): PlayerNo {
    if (playerNo === 1 || playerNo === 2)
        return playerNo;

    throw new Error(`Expected 1 or 2, not ${playerNo}`);
}


export function castNullablePlayerNo(playerNo: number | null): PlayerNo | null {
    return (playerNo !== null ? castPlayerNo(playerNo) : null);
}
