import {
    APIGameAnalysis,
    APIPlayerAnalysis,
} from "@/ts/business/api/analysis/APIGameAnalysis";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { MoveAnalysis } from "@/ts/business/analysis/MoveAnalysis";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


export class GameAnalysis {
    readonly lightStartSummary: string;
    readonly darkStartSummary: string;
    readonly lightEndSummary: string;
    readonly darkEndSummary: string;
    readonly debugOverview: string | null;
    readonly light: APIPlayerAnalysis;
    readonly dark: APIPlayerAnalysis;
    readonly lightCategoryCounts: Record<string, number>;
    readonly darkCategoryCounts: Record<string, number>;
    readonly moveAnalyses: MoveAnalysis[];
    readonly moveLWPs: number[];
    readonly hasBeenLimited: boolean;
    readonly isBareMinimum: boolean;

    constructor(
        lightStartSummary: string,
        darkStartSummary: string,
        lightEndSummary: string,
        darkEndSummary: string,
        debugOverview: string | null,
        light: APIPlayerAnalysis,
        dark: APIPlayerAnalysis,
        lightCategoryCounts: Record<string, number>,
        darkCategoryCounts: Record<string, number>,
        moveAnalyses: MoveAnalysis[],
        moveLWPs: number[],
        hasBeenLimited: boolean,
        isBareMinimum: boolean,
    ) {
        this.lightStartSummary = lightStartSummary;
        this.darkStartSummary = darkStartSummary;
        this.lightEndSummary = lightEndSummary;
        this.darkEndSummary = darkEndSummary;
        this.debugOverview = debugOverview;
        this.light = light;
        this.dark = dark;
        this.lightCategoryCounts = lightCategoryCounts;
        this.darkCategoryCounts = darkCategoryCounts;
        this.moveAnalyses = moveAnalyses;
        this.moveLWPs = moveLWPs;
        this.hasBeenLimited = hasBeenLimited;
        this.isBareMinimum = isBareMinimum;
    }

    getStartSummary(player: PlayerType): string {
        return (player === PlayerType.LIGHT ? this.lightStartSummary : this.darkStartSummary);
    }

    getEndSummary(player: PlayerType): string {
        return (player === PlayerType.LIGHT ? this.lightEndSummary : this.darkEndSummary);
    }

    static create(
        rules: RuleSet,
        context: MessageIOContext,
        apiGameAnalysis: APIGameAnalysis
    ): GameAnalysis {

        let lightCategoryCounts: Record<string, number> = {};
        let darkCategoryCounts: Record<string, number> = {};
        if (apiGameAnalysis.category_counts) {
            lightCategoryCounts = apiGameAnalysis.category_counts.light;
            darkCategoryCounts = apiGameAnalysis.category_counts.dark;
        }

        const moveAnalyses = [];
        for (const apiMoveAnalysis of apiGameAnalysis.moves) {
            moveAnalyses.push(MoveAnalysis.create(
                rules, context, apiMoveAnalysis,
            ));
        }
        return new GameAnalysis(
            apiGameAnalysis.light_start_summary,
            apiGameAnalysis.dark_start_summary,
            apiGameAnalysis.light_end_summary,
            apiGameAnalysis.dark_end_summary,
            apiGameAnalysis.debug_overview,
            apiGameAnalysis.light,
            apiGameAnalysis.dark,
            lightCategoryCounts,
            darkCategoryCounts,
            moveAnalyses,
            apiGameAnalysis.move_lwps,
            apiGameAnalysis.has_been_limited,
            apiGameAnalysis.is_bare_minimum,
        );
    }
}
