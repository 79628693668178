import { MessageOut } from "@/ts/business/api/MessageOut";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class MessageOutAnalyseGame extends MessageOut {
    public readonly gameID: string;

    constructor(gameID: string) {
        super();
        this.gameID = gameID;
    }

    write(_context: MessageIOContext): Record<string, any> {
        return { game_id: this.gameID };
    }
}
