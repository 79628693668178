import { JsonNotation } from "@/ts/royalur/notation/JsonNotation";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { BoardType } from "@/ts/royalur/model/shape/BoardType";
import { PathType } from "@/ts/royalur/model/path/PathType";
import { RoyalUrDice } from "@/ts/business/game/royalur/RoyalUrDice";
import { SimpleRuleSetProvider } from "@/ts/royalur/rules/simple/SimpleRuleSetProvider";
import { RoyalUrRolledDie } from "@/ts/business/game/royalur/RoyalUrRolledDie";
import { readJsonArray, readJsonBool, readJsonNumber } from "@/ts/util/json";
import { clamp } from "@/ts/util/numbers";


export class RoyalUrJsonNotation extends JsonNotation {

    public static readonly DIES_KEY = "dies";
    public static readonly OLD_UP_KEY = "up";
    public static readonly OLD_VARIATION_KEY = "variation";

    private static readonly VARIATION_OFFSET = 0.01;

    constructor() {
        super(
            BoardType.PARSING_MAP,
            PathType.PARSING_MAP,
            RoyalUrDice.PARSING_MAP,
            new SimpleRuleSetProvider(),
        );
    }

    writeDies(dies: RoyalUrRolledDie[]): number[] {
        const result = [];
        for (const die of dies) {
            // Add a small amount to avoid 0, which cannot have a sign.
            let value = die.getVariation() + RoyalUrJsonNotation.VARIATION_OFFSET;
            value *= (die.isUp() ? 1 : -1);
            result.push(value);
        }
        return result;
    }

    override writeRoll(roll: RoyalUrRoll): Record<string, any> {
        const baseline = super.writeRoll(roll);
        return {
            ...baseline,
            [RoyalUrJsonNotation.DIES_KEY]: this.writeDies(roll.getDie()),
        };
    }

    readDie(json: any): RoyalUrRolledDie {
        if (typeof json === "object") {
            const up = readJsonBool(json, "up");
            const variation = readJsonNumber(json, "variation");
            return new RoyalUrRolledDie(up, variation);

        } else if (typeof json === "number") {
            return new RoyalUrRolledDie(
                json > 0,
                clamp(Math.abs(json) - RoyalUrJsonNotation.VARIATION_OFFSET, 0, 1),
            );

        } else {
            throw new Error("Unable to read die");
        }
    }

    readDies(json: any[]): RoyalUrRolledDie[] {
        const dies: RoyalUrRolledDie[] = [];
        for (const dieJson of json) {
            dies.push(this.readDie(dieJson));
        }
        return dies;
    }

    override readRoll(
        rules: RuleSet,
        json: Record<string, any>,
    ): RoyalUrRoll {
        const baseline = super.readRoll(rules, json);

        const diesJson = readJsonArray(json, "dies");
        const dies = this.readDies(diesJson);
        const dice = RoyalUrDice.cast(rules.getSampleDice());
        const roll = dice.createRollFromDies(dies);

        if (roll.value() !== baseline.value()) {
            throw new Error(
                "Inconsistent roll serialisation: "
                + roll.value() + " != " + baseline.value(),
            );
        }
        return roll;
    }
}
