import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { readJsonChar, readJsonInt } from "@/ts/util/json";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { castPlayerNo } from "@/ts/business/game/PlayerNo";


export class GamePlayerDataNotation {
    public static readonly PLAYER_NO_KEY = "player_no";
    public static readonly PLAYER_TYPE_KEY = "player_type";

    writePlayerData(data: GamePlayerData): Record<string, any> {
        return {
            [GamePlayerDataNotation.PLAYER_NO_KEY]: data.getPlayerNo(),
            [GamePlayerDataNotation.PLAYER_TYPE_KEY]: data.getPlayerType().getChar(),
        };
    }

    readPlayerData(json: Record<string, any>): GamePlayerData {
        const playerNo = castPlayerNo(readJsonInt(json, GamePlayerDataNotation.PLAYER_NO_KEY));
        const typeChar = readJsonChar(json, GamePlayerDataNotation.PLAYER_TYPE_KEY);
        const type = PlayerType.getByChar(typeChar);
        return new GamePlayerData(playerNo, type);
    }
}
