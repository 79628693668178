import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerNo } from "@/ts/business/game/PlayerNo";

/**
 * Stores metadata about a player in a game.
 */
export class GamePlayerData {
    private readonly playerNo: PlayerNo;
    private readonly playerType: PlayerType;

    constructor(
        playerNo: PlayerNo,
        playerType: PlayerType,
    ) {
        this.playerNo = playerNo;
        this.playerType = playerType;
    }

    getPlayerNo(): PlayerNo {
        return this.playerNo;
    }

    getPlayerType(): PlayerType {
        return this.playerType;
    }

    equals(other: any): boolean {
        if (other.constructor !== GamePlayerData)
            return false;
        if (!(other instanceof GamePlayerData))
            throw new Error("Same constructor, but not instanceof!");

        return this.playerNo === other.playerNo
            && this.playerType === other.playerType;
    }
}
