import { MessageIn } from "@/ts/business/api/MessageIn";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { readJsonString } from "@/ts/util/json";


export class MessageInUploadedGame extends MessageIn {
    public finishedGameID: string | null = null;

    read(_context: MessageIOContext, json: Record<string, any>): void {
        this.finishedGameID = readJsonString(json, "finished_game_id");
    }
}
