

export class ErrorType {
    public static readonly UNKNOWN = new ErrorType("unknown");
    public static readonly OUTDATED_CLIENT = new ErrorType("outdated_client");
    public static readonly MUST_SIGN_IN = new ErrorType("must_sign_in");
    public static readonly REQUIRES_MEMBURSHIP = new ErrorType("requires_memburship");
    public static readonly EXCEEDED_PANDA_LIMIT = new ErrorType("exceeded_panda_limit");

    static values(): ErrorType[] {
        return [
            ErrorType.UNKNOWN,
            ErrorType.OUTDATED_CLIENT,
            ErrorType.MUST_SIGN_IN,
            ErrorType.REQUIRES_MEMBURSHIP,
            ErrorType.EXCEEDED_PANDA_LIMIT,
        ];
    }

    private readonly id: string;

    constructor(id: string) {
        this.id = id;
    }

    getID(): string {
        return this.id;
    }

    static getByID(id: string): ErrorType {
        for (const errorType of ErrorType.values()) {
            if (errorType.getID() === id)
                return errorType;
        }
        return ErrorType.UNKNOWN;
    }
}
