import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonDict, readJsonNumber, readNullableJsonDict } from "@/ts/util/json";
import { Game } from "@/ts/royalur/Game";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { TimeControlState } from "@/ts/business/lobby/TimeControlState";


export class PacketInGame extends PacketIn {
    public game: Game | null = null;
    public timeControlState: TimeControlState | null = null;

    constructor() {
        super(InboundPacketType.GAME);
    }

    getGame(): Game {
        if (this.game === null)
            throw new Error("Packet has not been read");

        return this.game;
    }

    getTimeControlState(): TimeControlState | null {
        if (this.game === null)
            throw new Error("Packet has not been read");

        return this.timeControlState;
    }

    override readContents(
        context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        const gameJson = readJsonDict(json, "game");
        this.game = context.getGameNotation().readGame(gameJson);

        const timeControlJson = readNullableJsonDict(json, "time_control");
        if (timeControlJson !== null) {
            const rollExpiryTimeMs = readJsonNumber(timeControlJson, "roll_expiry_time_ms");
            const moveExpiryTimeMs = readJsonNumber(timeControlJson, "move_expiry_time_ms");
            const gameAbandonTimeMs = readJsonNumber(timeControlJson, "game_abandon_time_ms");
            this.timeControlState = new TimeControlState(
                rollExpiryTimeMs, moveExpiryTimeMs, gameAbandonTimeMs,
            );
        }
    }
}
