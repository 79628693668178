import { Notation } from "@/ts/royalur/notation/Notation";
import { Piece } from "@/ts/royalur/model/Piece";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Game } from "@/ts/royalur/Game";
import { GameMetadata } from "@/ts/royalur/GameMetadata";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Tile } from "@/ts/royalur/model/Tile";
import { Move } from "@/ts/royalur/model/Move";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { PlayableGameState } from "@/ts/royalur/rules/state/PlayableGameState";
import { OngoingGameState } from "@/ts/royalur/rules/state/OngoingGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import {
    getJsonType,
    isJsonDict,
    readJsonArray,
    readJsonBool,
    readJsonDict,
    readJsonInt,
    readJsonString,
    readNullableJsonChar,
    readNullableJsonValue,
    writeNullableJsonValue,
} from "@/ts/util/json";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { RuleSetProvider } from "@/ts/royalur/rules/RuleSetProvider";
import { DiceFactory } from "@/ts/royalur/model/dice/DiceFactory";
import { BoardShapeFactory } from "@/ts/royalur/model/shape/BoardShapeFactory";
import { PathPairFactory } from "@/ts/royalur/model/path/PathPairFactory";
import { Board } from "@/ts/royalur/model/Board";
import { StateSource } from "@/ts/royalur/notation/StateSource";
import { PathPair } from "@/ts/royalur/model/path/PathPair";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { DerivedStateSource } from "@/ts/royalur/notation/DerivedStateSource";
import { FullStateSource } from "@/ts/royalur/notation/FullStateSource";
import { ResignedGameState } from "@/ts/royalur/rules/state/ResignedGameState";
import { AbandonedGameState } from "@/ts/royalur/rules/state/AbandonedGameState";
import { ControlGameState } from "@/ts/royalur/rules/state/ControlGameState";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";
import { BoardType } from "@/ts/royalur/model/shape/BoardType";
import { PathType } from "@/ts/royalur/model/path/PathType";
import { DiceType } from "@/ts/royalur/model/dice/DiceType";
import { SimpleRuleSetProvider } from "@/ts/royalur/rules/simple/SimpleRuleSetProvider";


export class JsonNotation extends Notation {
    /**
     * The latest version of the JSON notation. If any breaking changes
     * are made to the JSON notation, then this field will be updated
     * to reflect that.
     */
    public static readonly LATEST_VERSION = 2;

    public static readonly VERSION_KEY = "notation_version";
    public static readonly METADATA_KEY = "metadata";
    public static readonly SETTINGS_KEY = "settings";
    public static readonly BOARD_SHAPE_KEY = "board_shape";
    public static readonly PATHS_KEY = "paths";
    public static readonly DICE_KEY = "dice";
    public static readonly STARTING_PIECE_COUNT_KEY = "start_pieces";
    public static readonly SAFE_ROSETTES_KEY = "safe_rosettes";
    public static readonly ROSETTES_GRANT_EXTRA_ROLLS_KEY = "rosettes_grant_rolls";
    public static readonly CAPTURES_GRANT_EXTRA_ROLLS_KEY = "captures_grant_rolls";
    public static readonly INITIAL_STATE_KEY = "initial_state";
    public static readonly STATES_KEY = "states";
    public static readonly STATE_TYPE_KEY = "type";
    public static readonly STATE_TYPE_ROLLED = "roll";
    public static readonly STATE_TYPE_MOVED = "move";
    public static readonly STATE_TYPE_WAITING_FOR_ROLL = "wait4roll";
    public static readonly STATE_TYPE_WAITING_FOR_MOVE = "wait4move";
    public static readonly STATE_TYPE_RESIGNED = "resigned";
    public static readonly STATE_TYPE_ABANDONED = "abandoned";
    public static readonly STATE_TYPE_END = "win";
    public static readonly ROLL_KEY = "roll";
    public static readonly ROLL_VALUE_KEY = "value";
    public static readonly MOVE_KEY = "move";
    public static readonly MOVE_SOURCE_KEY = "src";
    public static readonly MOVE_DEST_KEY = "dest";
    public static readonly MOVE_CAPTURED_KEY = "captured";
    public static readonly AVAILABLE_MOVES_KEY = "moves";
    public static readonly OLD_PIECE_OWNER_KEY = "owner";
    public static readonly OLD_PIECE_INDEX_KEY = "index";
    public static readonly TURN_KEY = "turn";
    public static readonly CONTROL_PLAYER_KEY = "player";
    public static readonly ABANDONED_REASON_KEY = "reason";
    public static readonly WINNER_KEY = "winner";
    public static readonly BOARD_KEY = "board";
    public static readonly BOARD_PIECES_KEY = "pieces";
    public static readonly PLAYERS_KEY = "players";
    public static readonly PLAYER_PIECES_KEY = "pieces";
    public static readonly PLAYER_SCORE_KEY = "score";

    private readonly boardShapes: Record<string, BoardShapeFactory>;
    private readonly pathPairs: Record<string, PathPairFactory>;
    private readonly dice: Record<string, DiceFactory>;
    private readonly ruleSetProvider: RuleSetProvider;

    constructor(
        boardShapes?: Record<string, BoardShapeFactory>,
        paths?: Record<string, PathPairFactory>,
        dice?: Record<string, DiceFactory>,
        ruleSetProvider?: RuleSetProvider,
    ) {
        super();
        this.boardShapes = boardShapes ?? BoardType.PARSING_MAP;
        this.pathPairs = paths ?? PathType.PARSING_MAP;
        this.dice = dice ?? DiceType.PARSING_MAP;
        this.ruleSetProvider = ruleSetProvider ?? new SimpleRuleSetProvider();
    }

    writeRoll(roll: Roll): Record<string, any> {
        return {
            [JsonNotation.ROLL_VALUE_KEY]: roll.value(),
        };
    }

    writePiece(piece: Piece): any {
        const owner = piece.getOwner();
        let sign;
        if (owner === PlayerType.LIGHT) {
            sign = 1;
        } else if (owner === PlayerType.DARK) {
            sign = -1;
        } else {
            throw new Error(`Unknown player type ${owner.getName()}`);
        }
        return sign * (piece.getPathIndex() + 1);
    }

    writeMove(move: Move): Record<string, any> {
        let sourceJson: any = null;
        if (!move.isIntroduction()) {
            sourceJson = this.writePiece(move.getSourcePiece());
        }

        let destJson: any = null;
        if (!move.isScore()) {
            destJson = this.writePiece(move.getDestPiece());
        }

        let capturedJson: any = null;
        if (move.isCapture()) {
            capturedJson = this.writePiece(move.getCapturedPiece());
        }

        return {
            ...writeNullableJsonValue(JsonNotation.MOVE_SOURCE_KEY, sourceJson),
            ...writeNullableJsonValue(JsonNotation.MOVE_DEST_KEY, destJson),
            ...writeNullableJsonValue(JsonNotation.MOVE_CAPTURED_KEY, capturedJson),
        };
    }

    writeMoveList(moves: Move[]): any[] {
        const json: any[] = [];
        for (const move of moves) {
            json.push(this.writeMove(move));
        }
        return json;
    }

    writeBoard(board: Board): Record<string, any> {
        const pieces: Record<string, object> = {};
        for (const tile of board.getShape().getTiles()) {
            const piece = board.get(tile);
            if (piece !== null) {
                pieces[tile.toString()] = this.writePiece(piece);
            }
        }
        return {
            [JsonNotation.BOARD_PIECES_KEY]: pieces,
        };
    }

    writePlayerState(playerState: PlayerState): Record<string, any> {
        return {
            [JsonNotation.PLAYER_PIECES_KEY]: playerState.getPieceCount(),
            [JsonNotation.PLAYER_SCORE_KEY]: playerState.getScore(),
        };
    }

    writeRolledState(state: RolledGameState): Record<string, any> {
        return {
            [JsonNotation.ROLL_KEY]: this.writeRoll(state.getRoll()),
        };
    }

    writeMovedState(state: MovedGameState): Record<string, any> {
        return {
            [JsonNotation.ROLL_KEY]: this.writeRoll(state.getRoll()),
            [JsonNotation.MOVE_KEY]: this.writeMove(state.getMove()),
        };
    }

    writeActionState(state: ActionGameState): Record<string, any> {
        if (state instanceof RolledGameState) {
            return this.writeRolledState(state as RolledGameState);
        } else if (state instanceof MovedGameState) {
            return this.writeMovedState(state as MovedGameState);
        } else {
            throw new Error("Unrecognised action state type");
        }
    }

    writeWaitingForRollState(_state: WaitingForRollGameState): Record<string, any> {
        // Nothing to include.
        return {};
    }

    writeWaitingForMoveState(state: WaitingForMoveGameState): Record<string, any> {
        return {
            [JsonNotation.ROLL_KEY]: this.writeRoll(state.getRoll()),
        };
    }

    writePlayableState(state: PlayableGameState): object {
        if (state instanceof WaitingForRollGameState) {
            return this.writeWaitingForRollState(state as WaitingForRollGameState);
        } else if (state instanceof WaitingForMoveGameState) {
            return this.writeWaitingForMoveState(state as WaitingForMoveGameState);
        } else {
            throw new Error("Unrecognised playable state type");
        }
    }

    writeOngoingState(state: OngoingGameState): Record<string, any> {
        let stateJson;
        if (state instanceof ActionGameState) {
            stateJson = this.writeActionState(state as ActionGameState);
        } else if (state instanceof PlayableGameState) {
            stateJson = this.writePlayableState(state as PlayableGameState);
        } else {
            throw new Error("Unrecognised ongoing state type");
        }

        return {
            [JsonNotation.TURN_KEY]: state.getTurn().getChar(),
            ...stateJson,
        };
    }

    writeResignedState(_state: ResignedGameState): Record<string, any> {
        return {};
    }

    writeAbandonedState(state: AbandonedGameState): Record<string, any> {
        return {
            [JsonNotation.ABANDONED_REASON_KEY]: state.getReason().getID(),
        };
    }

    writeControlState(state: ControlGameState): Record<string, any> {
        let stateJson;
        if (state instanceof ResignedGameState) {
            stateJson = this.writeResignedState(state);
        } else if (state instanceof AbandonedGameState) {
            stateJson = this.writeAbandonedState(state);
        } else {
            throw new Error("Unrecognised control state type");
        }

        if (state.hasPlayer()) {
            stateJson[JsonNotation.CONTROL_PLAYER_KEY] = state.getPlayer().getChar();
        }
        return stateJson;
    }

    writeEndState(state: EndGameState): Record<string, any> {
        const json: Record<string, any> = {};
        if (state.hasWinner()) {
            json[JsonNotation.WINNER_KEY] = state.getWinner().getChar();
        }
        return json;
    }

    getStateType(state: GameState): string {
        if (state instanceof RolledGameState)
            return JsonNotation.STATE_TYPE_ROLLED;
        if (state instanceof MovedGameState)
            return JsonNotation.STATE_TYPE_MOVED;
        if (state instanceof WaitingForRollGameState)
            return JsonNotation.STATE_TYPE_WAITING_FOR_ROLL;
        if (state instanceof WaitingForMoveGameState)
            return JsonNotation.STATE_TYPE_WAITING_FOR_MOVE;
        if (state instanceof ResignedGameState)
            return JsonNotation.STATE_TYPE_RESIGNED;
        if (state instanceof AbandonedGameState)
            return JsonNotation.STATE_TYPE_ABANDONED;
        if (state instanceof EndGameState)
            return JsonNotation.STATE_TYPE_END;

        throw new Error("Unknown game state type");
    }

    writeState(state: GameState): Record<string, any> {
        let stateJson;
        if (state instanceof OngoingGameState) {
            stateJson = this.writeOngoingState(state);
        } else if (state instanceof ControlGameState) {
            stateJson = this.writeControlState(state);
        } else if (state instanceof EndGameState) {
            stateJson = this.writeEndState(state);
        } else {
            throw new Error("Unknown game state type");
        }

        return {
            [JsonNotation.STATE_TYPE_KEY]: this.getStateType(state),
            ...stateJson,
        };
    }

    writeStates(states: GameState[]): any[] {
        const json: object[] = [];
        for (const state of states) {
            json.push(this.writeState(state));
        }
        return json;
    }

    writeInitialState(state: GameState): Record<string, any> {
        const stateJson = this.writeState(state);
        return {
            [JsonNotation.BOARD_KEY]: this.writeBoard(state.getBoard()),
            [JsonNotation.PLAYERS_KEY]: {
                [PlayerType.LIGHT.getChar()]: this.writePlayerState(state.getLightPlayer()),
                [PlayerType.DARK.getChar()]: this.writePlayerState(state.getDarkPlayer()),
            },
            ...stateJson,
        };
    }

    writeGameSettings(settings: GameSettings): Record<string, any> {
        return {
            [JsonNotation.BOARD_SHAPE_KEY]: settings.getBoardShape().getID(),
            [JsonNotation.PATHS_KEY]: settings.getPaths().getID(),
            [JsonNotation.DICE_KEY]: settings.getDice().getID(),
            [JsonNotation.STARTING_PIECE_COUNT_KEY]: settings.getStartingPieceCount(),
            [JsonNotation.SAFE_ROSETTES_KEY]: settings.areRosettesSafe(),
            [JsonNotation.ROSETTES_GRANT_EXTRA_ROLLS_KEY]: settings.doRosettesGrantExtraRolls(),
            [JsonNotation.CAPTURES_GRANT_EXTRA_ROLLS_KEY]: settings.doCapturesGrantExtraRolls(),
        };
    }

    writeMetadata(_metadata: GameMetadata): Record<string, any> {
        return {};
    }

    writeGame(game: Game): Record<string, any> {
        const states = game.getLandmarkStates();
        return {
            [JsonNotation.VERSION_KEY]: JsonNotation.LATEST_VERSION,

            [JsonNotation.METADATA_KEY]: this.writeMetadata(
                game.getMetadata(),
            ),

            [JsonNotation.SETTINGS_KEY]: this.writeGameSettings(
                game.getRules().getSettings(),
            ),

            [JsonNotation.INITIAL_STATE_KEY]: this.writeInitialState(
                states[0],
            ),

            [JsonNotation.STATES_KEY]: this.writeStates(
                states.slice(1),
            ),
        };
    }

    encodeGame(game: Game): string {
        const json = this.writeGame(game);
        return JSON.stringify(json);
    }

    readRoll(
        rules: RuleSet,
        json: Record<string, any>,
    ): Roll {
        const value = readJsonInt(json, JsonNotation.ROLL_VALUE_KEY);
        return rules.getDiceFactory().createRoll(value);
    }

    readPiece(
        rules: RuleSet,
        json: any,
    ): Piece {

        if (typeof json === "number") {
            const owner = (json < 0 ? PlayerType.DARK : PlayerType.LIGHT);
            const index = Math.abs(json) - 1;
            return rules.getPieceProvider().create(
                owner, index,
            );
        }


        const ownerChar = readJsonString(json, JsonNotation.OLD_PIECE_OWNER_KEY);
        const owner = PlayerType.getByChar(ownerChar);
        const pathIndex = readJsonInt(json, JsonNotation.OLD_PIECE_INDEX_KEY);
        return rules.getPieceProvider().create(owner, pathIndex);
    }

    private getTileFromPiece(
        paths: PathPair,
        piece: Piece,
    ): Tile {
        return paths.get(piece.getOwner())[piece.getPathIndex()];
    }

    readMove(
        rules: RuleSet,
        json: Record<string, any>,
    ): Move {
        const paths = rules.getPaths();

        const sourceJson = readNullableJsonValue(json, JsonNotation.MOVE_SOURCE_KEY);
        const source = (sourceJson !== null ? this.readPiece(rules, sourceJson) : null);
        const sourceTile = (source !== null ? this.getTileFromPiece(paths, source) : null);

        const destJson = readNullableJsonValue(json, JsonNotation.MOVE_DEST_KEY);
        const dest: Piece | null = (
            destJson !== null ? this.readPiece(rules, destJson) : null
        );
        const destTile = (dest !== null ? this.getTileFromPiece(paths, dest) : null);

        const capturedJson = readNullableJsonValue(json, JsonNotation.MOVE_CAPTURED_KEY);
        const captured: Piece | null = (
            capturedJson !== null ? this.readPiece(rules, capturedJson) : null
        );

        const player = (source !== null ? source.getOwner() : (dest !== null ? dest.getOwner() : null));
        if (player === null)
            throw new Error("Missing source AND dest, but we need at least one of them!");

        return new Move(
            player,
            sourceTile, source,
            destTile, dest,
            captured,
        );
    }

    readMoveList(
        rules: RuleSet,
        json: any[],
    ): Move[] {
        const moves: Move[] = [];
        for (const moveJson of json) {
            moves.push(this.readMove(rules, moveJson));
        }
        return moves;
    }

    readBoard(
        rules: RuleSet,
        json: Record<string, any>,
    ): Board {
        const board = new Board(rules.getBoardShape());
        const piecesJson = readJsonDict(json, JsonNotation.BOARD_PIECES_KEY);
        for (const [tileString, pieceJson] of Object.entries(piecesJson)) {
            const tile = Tile.fromString(tileString);
            const piece = this.readPiece(rules, pieceJson);
            board.set(tile, piece);
        }
        return board;
    }

    readPlayerState(
        rules: RuleSet,
        playerType: PlayerType,
        json: Record<string, any>,
    ): PlayerState {
        const pieces = readJsonInt(json, JsonNotation.PLAYER_PIECES_KEY);
        const score = readJsonInt(json, JsonNotation.PLAYER_SCORE_KEY);
        return rules.getPlayerStateProvider().create(
            playerType, pieces, score,
        );
    }

    isActionStateType(stateType: string) {
        return stateType === JsonNotation.STATE_TYPE_ROLLED
            || stateType === JsonNotation.STATE_TYPE_MOVED;
    }

    isPlayableStateType(stateType: string) {
        return stateType === JsonNotation.STATE_TYPE_WAITING_FOR_ROLL
            || stateType === JsonNotation.STATE_TYPE_WAITING_FOR_MOVE;
    }

    isOngoingStateType(stateType: string) {
        return this.isActionStateType(stateType) || this.isPlayableStateType(stateType);
    }

    isControlStateType(stateType: string) {
        return stateType === JsonNotation.STATE_TYPE_RESIGNED
            || stateType === JsonNotation.STATE_TYPE_ABANDONED;
    }

    readRolledState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        turn: PlayerType,
    ): RolledGameState {
        const rollJson = readJsonDict(json, JsonNotation.ROLL_KEY);
        const roll = this.readRoll(rules, rollJson);
        return stateSource.createRolledState(rules, turn, roll);
    }

    readMovedState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        turn: PlayerType,
    ): MovedGameState {
        const rollJson = readJsonDict(json, JsonNotation.ROLL_KEY);
        const roll = this.readRoll(rules, rollJson);

        const moveJson = readJsonDict(json, JsonNotation.MOVE_KEY);
        const move = this.readMove(rules, moveJson);

        return stateSource.createMovedState(rules, turn, roll, move);
    }

    readWaitingForRollState(
        rules: RuleSet,
        stateSource: StateSource,
        _json: Record<string, any>,
        turn: PlayerType,
    ): WaitingForRollGameState {
        return stateSource.createWaitingForRollState(rules, turn);
    }

    readWaitingForMoveState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        turn: PlayerType,
    ): WaitingForMoveGameState {
        const rollJson = readJsonDict(json, JsonNotation.ROLL_KEY);
        const roll = this.readRoll(rules, rollJson);
        return stateSource.createWaitingForMoveState(rules, turn, roll);
    }

    readActionState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        stateType: string,
        turn: PlayerType,
    ): ActionGameState {
        if (stateType === JsonNotation.STATE_TYPE_ROLLED) {
            return this.readRolledState(rules, stateSource, json, turn);
        } else if (stateType === JsonNotation.STATE_TYPE_MOVED) {
            return this.readMovedState(rules, stateSource, json, turn);
        } else {
            throw new Error(`Unknown action state type ${stateType}`);
        }
    }

    readPlayableState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        stateType: string,
        turn: PlayerType,
    ): PlayableGameState {
        if (stateType === JsonNotation.STATE_TYPE_WAITING_FOR_ROLL) {
            return this.readWaitingForRollState(
                rules, stateSource, json, turn,
            );
        } else if (stateType === JsonNotation.STATE_TYPE_WAITING_FOR_MOVE) {
            return this.readWaitingForMoveState(
                rules, stateSource, json, turn,
            );
        } else {
            throw new Error(`Unknown playable state type ${stateType}`);
        }
    }

    readOngoingState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        stateType: string,
    ): OngoingGameState {
        const turnChar = readJsonString(json, JsonNotation.TURN_KEY);
        const turn = PlayerType.getByChar(turnChar);

        if (this.isActionStateType(stateType)) {
            return this.readActionState(
                rules, stateSource, json, stateType, turn,
            );
        } else if (this.isPlayableStateType(stateType)) {
            return this.readPlayableState(
                rules, stateSource, json, stateType, turn,
            );
        } else {
            throw new Error(`Unknown ongoing state type ${stateType}`);
        }
    }

    readResignedState(
        rules: RuleSet,
        stateSource: StateSource,
        _json: Record<string, any>,
        player: PlayerType,
    ): ResignedGameState {
        return stateSource.createResignedState(rules, player);
    }

    readAbandonedState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        player: PlayerType | null,
    ): AbandonedGameState {
        const reasonID = readJsonString(json, JsonNotation.ABANDONED_REASON_KEY);
        const reason = AbandonReason.getByID(reasonID);
        return stateSource.createAbandonedState(rules, reason, player);
    }

    readControlState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
        stateType: string,
    ): ControlGameState {
        const playerChar = readNullableJsonChar(json, JsonNotation.CONTROL_PLAYER_KEY);
        const player = (playerChar !== null ? PlayerType.getByChar(playerChar) : null);

        if (stateType === JsonNotation.STATE_TYPE_RESIGNED) {
            if (player === null)
                throw new Error("player should not be null for a resigned game state");

            return this.readResignedState(rules, stateSource, json, player);

        } else if (stateType === JsonNotation.STATE_TYPE_ABANDONED) {
            return this.readAbandonedState(rules, stateSource, json, player);

        } else {
            throw new Error(`Unknown control state type: ${stateType}`);
        }
    }

    readEndState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
    ): EndGameState {
        const winnerChar = readJsonString(json, JsonNotation.WINNER_KEY);
        const winner = PlayerType.getByChar(winnerChar);
        return stateSource.createEndState(rules, winner);
    }

    readState(
        rules: RuleSet,
        stateSource: StateSource,
        json: Record<string, any>,
    ): GameState {
        const stateType = readJsonString(json, JsonNotation.STATE_TYPE_KEY);

        if (this.isOngoingStateType(stateType)) {
            return this.readOngoingState(rules, stateSource, json, stateType);
        } else if (this.isControlStateType(stateType)) {
            return this.readControlState(rules, stateSource, json, stateType);
        } else if (stateType === JsonNotation.STATE_TYPE_END) {
            return this.readEndState(rules, stateSource, json);
        } else {
            throw new Error(`Unknown state type ${stateType}`);
        }
    }

    readCompleteState(
        rules: RuleSet,
        json: Record<string, any>,
    ): GameState {
        const boardJson = readJsonDict(json, JsonNotation.BOARD_KEY);
        const playersJson = readJsonDict(json, JsonNotation.PLAYERS_KEY);
        const lightPlayerJson = readJsonDict(playersJson, PlayerType.LIGHT.getChar());
        const darkPlayerJson = readJsonDict(playersJson, PlayerType.DARK.getChar());

        const board = this.readBoard(rules, boardJson);
        const lightPlayer = this.readPlayerState(rules, PlayerType.LIGHT, lightPlayerJson);
        const darkPlayer = this.readPlayerState(rules, PlayerType.DARK, darkPlayerJson);

        const stateSource = new FullStateSource(board, lightPlayer, darkPlayer);
        return this.readState(rules, stateSource, json);
    }

    readStates(
        rules: RuleSet,
        initialState: GameState,
        statesJson: any[],
    ): GameState[] {
        const stateSource = new DerivedStateSource(initialState);
        let lastIndex = -1;
        for (const json of statesJson) {
            if (!isJsonDict(json))
                throw new Error(`states contains non-dictionary entry: ${getJsonType(json)}`);

            const state = this.readState(rules, stateSource, json);
            const index = stateSource.lastIndexOf(state);
            if (index <= lastIndex)
                throw new Error("DerivedStateSource did not include states in read order");

            lastIndex = index;
        }
        return stateSource.getAllStates();
    }

    readGameSettings(json: object): GameSettings {
        const boardID = readJsonString(json, JsonNotation.BOARD_SHAPE_KEY);
        const pathID = readJsonString(json, JsonNotation.PATHS_KEY);
        const diceID = readJsonString(json, JsonNotation.DICE_KEY);
        const startingPieceCount = readJsonInt(json, JsonNotation.STARTING_PIECE_COUNT_KEY);
        const safeRosettes = readJsonBool(json, JsonNotation.SAFE_ROSETTES_KEY);
        const rosettesGrantExtraRolls = readJsonBool(
            json, JsonNotation.ROSETTES_GRANT_EXTRA_ROLLS_KEY,
        );
        const capturesGrantExtraRolls = readJsonBool(
            json, JsonNotation.CAPTURES_GRANT_EXTRA_ROLLS_KEY,
        );

        const board = this.boardShapes[boardID];
        const path = this.pathPairs[pathID];
        const dice = this.dice[diceID];
        if (!board)
            throw new Error(`Unknown board shape ${boardID}`);
        if (!path)
            throw new Error(`Unknown path pair ${pathID}`);
        if (!dice)
            throw new Error(`Unknown dice ${diceID}`);

        return new GameSettings(
            board.createBoardShape(),
            path.createPathPair(),
            dice,
            startingPieceCount,
            safeRosettes,
            rosettesGrantExtraRolls,
            capturesGrantExtraRolls,
        );
    }

    readMetadata(_json: object): GameMetadata {
        return new GameMetadata();
    }

    readGameV1Or2(json: Record<string, any>): Game {
        const metadataJson = readJsonDict(json, JsonNotation.METADATA_KEY);
        const metadata = this.readMetadata(metadataJson);

        const settingsJson = readJsonDict(json, JsonNotation.SETTINGS_KEY);
        const settings = this.readGameSettings(settingsJson);
        const rules = this.ruleSetProvider.create(settings);

        const initialStateJson = readJsonDict(json, JsonNotation.INITIAL_STATE_KEY);
        const initialState = this.readCompleteState(rules, initialStateJson);

        const statesJson = readJsonArray(json, JsonNotation.STATES_KEY);
        const states = this.readStates(rules, initialState, statesJson);
        return new Game(rules, states, metadata);
    }

    readGame(json: Record<string, any>): Game {
        const version = readJsonInt(json, JsonNotation.VERSION_KEY);
        if (version === 1 || version === 2)
            return this.readGameV1Or2(json);

        throw new Error(`Unknown JSON-Notation version: ${version}`);
    }

    override decodeGame(encoded: string): Game {
        const json = JSON.parse(encoded);
        if (!isJsonDict(json))
            throw new Error(`Invalid JSON: Expected a dictionary, not ${getJsonType(json)}`);

        return this.readGame(json);
    }
}
