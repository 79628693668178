import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";
import { BotLobbyPlayer } from "@/ts/business/lobby/BotLobbyPlayer";
import { GamePlayerDataNotation } from "@/ts/business/api/game/GamePlayerDataNotation";
import { LobbyPlayerType } from "@/ts/business/lobby/LobbyPlayerType";
import {
    readJsonDate,
    readJsonString, readNullableJsonBool,
    readNullableJsonDict,
} from "@/ts/util/json";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { APINameColour, APIPublicGamePreferences, APIUser } from "@/ts/business/api/api_schema";
import { BotType } from "@/ts/business/game/BotType";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";
import { LobbySummary } from "@/ts/business/lobby/LobbySummary";


export class LobbyNotation {
    public static readonly LOBBY_PLAYER_TYPE_KEY = "player_type";
    public static readonly PLAYER_DATA_KEY = "data";
    public static readonly HUMAN_PLAYER_USER_INFO_KEY = "user_info";
    public static readonly HUMAN_PLAYER_USER_PREF_KEY = "user_pref";
    public static readonly USER_INFO_PUBLIC_ID_KEY = "public_id";
    public static readonly USER_INFO_USERNAME_KEY = "username";
    public static readonly USER_INFO_DISPLAY_NAME_KEY = "display_name";
    public static readonly USER_INFO_HAS_ROLE_MEMBUR_KEY = "has_role_membur";
    public static readonly USER_INFO_HAS_ROLE_TEAM_KEY = "has_role_team";
    public static readonly USER_INFO_HAS_ROLE_ADMIN_KEY = "has_role_admin";
    public static readonly USER_INFO_CREATED_AT_KEY = "created_at";
    public static readonly USER_PREF_NAME_COLOUR_KEY = "name_colour";
    public static readonly USER_PREF_DISABLED_REACTIONS_KEY = "disabled_reactions";
    public static readonly BOT_PLAYER_TYPE = "bot_type";

    private readonly playerDataNotation: GamePlayerDataNotation;

    constructor(playerDataNotation: GamePlayerDataNotation) {
        this.playerDataNotation = playerDataNotation;
    }

    writeLobbyUserInfo(user: APIUser | null): Record<string, any> | null {
        if (user === null)
            return null;

        return {
            [LobbyNotation.USER_INFO_PUBLIC_ID_KEY]: user.publicID,
            [LobbyNotation.USER_INFO_USERNAME_KEY]: user.username,
            [LobbyNotation.USER_INFO_DISPLAY_NAME_KEY]: user.displayName,
            [LobbyNotation.USER_INFO_HAS_ROLE_MEMBUR_KEY]: user.hasRoleMembur,
            [LobbyNotation.USER_INFO_HAS_ROLE_TEAM_KEY]: user.hasRoleTeam,
            [LobbyNotation.USER_INFO_HAS_ROLE_ADMIN_KEY]: user.hasRoleAdmin,
            [LobbyNotation.USER_INFO_CREATED_AT_KEY]: user.createdAt.toISOString(),
        };
    }

    writeLobbyUserPreferences(pref: APIPublicGamePreferences | null): Record<string, any> | null {
        if (pref === null)
            return null;

        return {
            [LobbyNotation.USER_PREF_NAME_COLOUR_KEY]: pref.nameColour,
            [LobbyNotation.USER_PREF_DISABLED_REACTIONS_KEY]: pref.disabledReactions,
        };
    }

    writeHumanLobbyPlayer(humanPlayer: HumanLobbyPlayer) {
        const user = humanPlayer.getUserOrNull();
        const pref = humanPlayer.getPreferences();
        return {
            [LobbyNotation.HUMAN_PLAYER_USER_INFO_KEY]: this.writeLobbyUserInfo(user),
            [LobbyNotation.HUMAN_PLAYER_USER_PREF_KEY]: this.writeLobbyUserPreferences(pref),
        };
    }

    writeBotLobbyPlayer(botPlayer: BotLobbyPlayer) {
        return {
            [LobbyNotation.BOT_PLAYER_TYPE]: botPlayer.getBotType().getID(),
        };
    }

    writeLobbyPlayer(lobbyPlayer: LobbyPlayer) {
        const playerData = lobbyPlayer.getGameData();

        let dataJson;
        if (playerData !== null) {
            dataJson = this.playerDataNotation.writePlayerData(playerData);
        } else {
            dataJson = null;
        }

        let playerJson;
        if (lobbyPlayer instanceof HumanLobbyPlayer) {
            playerJson = this.writeHumanLobbyPlayer(lobbyPlayer);
        } else if (lobbyPlayer instanceof BotLobbyPlayer) {
            playerJson = this.writeBotLobbyPlayer(lobbyPlayer);
        } else {
            throw new Error("Unknown lobby player type " + lobbyPlayer.getType().getID());
        }

        return {
            [LobbyNotation.LOBBY_PLAYER_TYPE_KEY]: lobbyPlayer.getType().getID(),
            [LobbyNotation.PLAYER_DATA_KEY]: dataJson,
            ...playerJson,
        };
    }

    readLobbyUserInfo(json: Record<string, any>): APIUser {
        const publicID = readJsonString(json, LobbyNotation.USER_INFO_PUBLIC_ID_KEY);
        const username = readJsonString(json, LobbyNotation.USER_INFO_USERNAME_KEY);
        const displayName = readJsonString(json, LobbyNotation.USER_INFO_DISPLAY_NAME_KEY);
        const hasRoleMembur = readNullableJsonBool(
            json, LobbyNotation.USER_INFO_HAS_ROLE_MEMBUR_KEY,
        ) ?? false;
        const hasRoleTeam = readNullableJsonBool(
            json, LobbyNotation.USER_INFO_HAS_ROLE_TEAM_KEY,
        ) ?? false;
        const hasRoleAdmin = readNullableJsonBool(
            json, LobbyNotation.USER_INFO_HAS_ROLE_ADMIN_KEY,
        ) ?? false;
        const createdAt = readJsonDate(json, LobbyNotation.USER_INFO_CREATED_AT_KEY);
        return {
            publicID, username, displayName, createdAt,
            hasRoleMembur, hasRoleTeam, hasRoleAdmin,
        };
    }

    readLobbyUserPreferences(json: Record<string, any>): APIPublicGamePreferences {
        const nameColour = readJsonString(json, LobbyNotation.USER_PREF_NAME_COLOUR_KEY);
        const disabledReactions = readNullableJsonBool(
            json, LobbyNotation.USER_PREF_DISABLED_REACTIONS_KEY
        );
        return {
            nameColour: APINameColour.parse(nameColour),
            disabledReactions: disabledReactions ?? false,
        };
    }

    readHumanLobbyPlayer(json: Record<string, any>, data: GamePlayerData | null): HumanLobbyPlayer {
        const userJson = readNullableJsonDict(json, LobbyNotation.HUMAN_PLAYER_USER_INFO_KEY);
        const user = (userJson !== null ? this.readLobbyUserInfo(userJson) : null);

        const prefJson = readNullableJsonDict(json, LobbyNotation.HUMAN_PLAYER_USER_PREF_KEY);
        const pref = (prefJson !== null ? this.readLobbyUserPreferences(prefJson) : null);

        return new HumanLobbyPlayer(user, data, pref);
    }

    readBotLobbyPlayer(json: Record<string, any>, data: GamePlayerData | null): BotLobbyPlayer {
        const botTypeID = readJsonString(json, LobbyNotation.BOT_PLAYER_TYPE);
        const botType = BotType.getByID(botTypeID);
        return new BotLobbyPlayer(botType, data);
    }

    readLobbyPlayer(json: Record<string, any>): LobbyPlayer {
        const playerTypeID = readJsonString(json, LobbyNotation.LOBBY_PLAYER_TYPE_KEY);
        const playerType = LobbyPlayerType.getByID(playerTypeID);

        const dataJson = readNullableJsonDict(json, LobbyNotation.PLAYER_DATA_KEY);
        const data = (dataJson ? this.playerDataNotation.readPlayerData(dataJson) : null);

        if (playerType === LobbyPlayerType.HUMAN) {
            return this.readHumanLobbyPlayer(json, data);
        } else if (playerType === LobbyPlayerType.BOT) {
            return this.readBotLobbyPlayer(json, data);
        } else {
            throw new Error("Unknown lobby player type " + playerType.getID());
        }
    }

    readOptionalLobbyPlayerField(
        json: Record<string, any>, fieldName: string,
    ): LobbyPlayer | null {
        const playerJson = readNullableJsonDict(json, fieldName);
        return (playerJson ? this.readLobbyPlayer(playerJson) : null);
    }

    readLobbySummary(json: Record<string, any>): LobbySummary {
        const lobbyID = readJsonString(json, "lobby_id");
        const gameMode = GameMode.getByID(readJsonString(json, "game_mode"));
        const settings = GameType.getByID(readJsonString(json, "game_settings"));
        const player1 = this.readOptionalLobbyPlayerField(json, "player1");
        const player2 = this.readOptionalLobbyPlayerField(json, "player2");
        return new LobbySummary(lobbyID, gameMode, settings, player1, player2);
    }

    readLobbySummaries(json: Record<string, any>[]): LobbySummary[] {
        const summaries: LobbySummary[] = [];
        for (const entry of json) {
            summaries.push(this.readLobbySummary(entry));
        }
        return summaries;
    }
}
