import { GameState } from "../rules/state/GameState";
import { StateSource } from "@/ts/royalur/notation/StateSource";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { Move } from "@/ts/royalur/model/Move";
import { ResignedGameState } from "@/ts/royalur/rules/state/ResignedGameState";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";
import { AbandonedGameState } from "@/ts/royalur/rules/state/AbandonedGameState";

/**
 * Produces game states from previous game states using the actions
 * that were taken in a game. This effectively simulates games and
 * uses saved information to fill in the gaps and as a sanity check.
 */
export class DerivedStateSource extends StateSource {
    private readonly states: GameState[];
    private stateIndex: number;

    constructor(initialState: GameState) {
        super();
        this.states = [initialState];
        this.stateIndex = 0;
    }

    getAllStates(): GameState[] {
        return this.states;
    }

    lastIndexOf(state: GameState): number {
        for (let index = this.states.length - 1; index >= 0; --index) {
            if (state.equals(this.states[index]))
                return index;
        }
        throw new Error("State could not be found");
    }

    private peekState(): GameState {
        if (this.stateIndex >= this.states.length)
            throw new Error("No available states!");
        return this.states[this.stateIndex];
    }

    private nextState(): GameState {
        if (this.stateIndex >= this.states.length)
            throw new Error("No available states!");

        const index = this.stateIndex;
        this.stateIndex += 1;
        return this.states[index];
    }

    private getCurrentState(): GameState {
        if (this.stateIndex === this.states.length) {
            return this.states[this.stateIndex - 1];
        } else {
            return this.states[this.stateIndex];
        }
    }

    private pushStates(states: GameState[]) {
        if (this.stateIndex < this.states.length)
            throw new Error("There are remaining unused states!");
        this.states.push(...states);
    }

    override createRolledState(
        rules: RuleSet, _turn: PlayerType, roll: Roll,
    ): RolledGameState {
        const precedingState = this.nextState();
        if (!(precedingState instanceof WaitingForRollGameState))
            throw new Error("Preceding state is not a WaitingForRollGameState");

        this.pushStates(rules.applyRoll(precedingState, roll));
        const state = this.nextState();
        if (!(state instanceof RolledGameState))
            throw new Error("The state was not a RolledGameState after applying a roll");

        return state;
    }

    override createMovedState(
        rules: RuleSet, turn: PlayerType, roll: Roll, move: Move,
    ): MovedGameState {
        // Support for implied roll states from moves.
        if (this.peekState() instanceof WaitingForRollGameState) {
            this.createRolledState(rules, turn, roll);
        }

        const precedingState = this.nextState();
        if (!(precedingState instanceof WaitingForMoveGameState))
            throw new Error("Preceding state is not a WaitingForMoveGameState");

        this.pushStates(rules.applyMove(precedingState, move));
        const state = this.nextState();
        if (!(state instanceof MovedGameState))
            throw new Error("The state was not a MovedGameState after applying a roll");

        return state;
    }

    override createWaitingForRollState(
        _rules: RuleSet, turn: PlayerType,
    ): WaitingForRollGameState {
        const state = this.getCurrentState();
        if (!(state instanceof WaitingForRollGameState))
            throw new Error("The state is not a WaitingForRollGameState");

        if (state.getTurn() !== turn) {
            throw new Error(
                "Inconsistent derivation! "
                + `Expected turn = ${turn.getName()}, `
                + `actual turn = ${state.getTurn().getName()}`,
            );
        }
        return state;
    }

    override createWaitingForMoveState(
        _rules: RuleSet, turn: PlayerType, roll: Roll,
    ): WaitingForMoveGameState {
        const state = this.getCurrentState();
        if (!(state instanceof WaitingForMoveGameState))
            throw new Error("The state is not a WaitingForMoveGameState");

        if (state.getTurn() !== turn) {
            throw new Error(
                "Inconsistent derivation! "
                + `Expected turn = ${turn.getName()}, `
                + `actual turn = ${state.getTurn().getName()}`,
            );
        }
        if (state.getRoll().value() !== roll.value()) {
            throw new Error(
                "Inconsistent derivation! "
                + `Expected roll value = ${roll.value()}, `
                + `actual roll value = ${state.getRoll().value()}`,
            );
        }
        return state;
    }

    override createResignedState(
        rules: RuleSet, player: PlayerType,
    ): ResignedGameState {
        const currentState = this.nextState();
        if (currentState instanceof EndGameState)
            throw new Error("Game is already finished");

        this.pushStates(rules.applyResign(currentState, player));
        const state = this.nextState();
        if (!(state instanceof ResignedGameState))
            throw new Error("The state is not a ResignedGameState");

        return state;
    }

    override createAbandonedState(
        rules: RuleSet, reason: AbandonReason, player: PlayerType | null,
    ): AbandonedGameState {
        const currentState = this.nextState();
        if (currentState instanceof EndGameState)
            throw new Error("Game is already finished");

        this.pushStates(rules.applyAbandon(currentState, reason, player));
        const state = this.nextState();
        if (!(state instanceof AbandonedGameState))
            throw new Error("The state is not a ResignedGameState");

        return state;
    }

    override createEndState(
        _rules: RuleSet, winner: PlayerType | null,
    ): EndGameState {
        const state = this.getCurrentState();
        if (!(state instanceof EndGameState))
            throw new Error("The state is not a EndGameState");

        const stateWinner = (state.hasWinner() ? state.getWinner() : null);
        if (state.getWinner() !== winner) {
            throw new Error(
                "Inconsistent derivation! "
                + `Expected winner = ${winner !== null ? winner.getName() : null}, `
                + `actual winner = ${stateWinner !== null ? stateWinner.getName() : null}`,
            );
        }
        return state;
    }
}
