import { Dice } from "@/ts/royalur/model/dice/Dice";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { DiceType } from "@/ts/royalur/model/dice/DiceType";
import { rand, randShuffle } from "@/ts/util/random";
import { RoyalUrRolledDie } from "@/ts/business/game/royalur/RoyalUrRolledDie";
import { RoyalUrDiceFactory } from "@/ts/business/game/royalur/RoyalUrDiceFactory";
import { DiceFactory } from "@/ts/royalur/model/dice/DiceFactory";


/**
 * A die for generating RoyalUr.net dice rolls.
 */
export class RoyalUrDice extends Dice {

    public static readonly FOUR_BINARY: RoyalUrDiceFactory
        = new RoyalUrDiceFactory(DiceType.FOUR_BINARY);

    public static readonly THREE_BINARY_0EQ4: RoyalUrDiceFactory
        = new RoyalUrDiceFactory(DiceType.THREE_BINARY_0EQ4);

    static values(): RoyalUrDiceFactory[] {
        return [
            RoyalUrDice.FOUR_BINARY,
            RoyalUrDice.THREE_BINARY_0EQ4,
        ];
    }

    static find(diceType: DiceType): RoyalUrDiceFactory {
        for (const dice of RoyalUrDice.values()) {
            if (dice.getType() === diceType)
                return dice;
        }
        throw new Error(`Unable to find RoyalUrDice for DiceType ${diceType.getName()}`);
    }

    static createParsingMap(): Record<string, RoyalUrDiceFactory> {
        const map: Record<string, RoyalUrDiceFactory> = {};
        for (const [key, value] of Object.entries(DiceType.PARSING_MAP)) {
            if (value instanceof DiceType) {
                map[key] = RoyalUrDice.find(value);
            }
        }
        map["RoyalUr"] = RoyalUrDice.FOUR_BINARY;
        map["royalur"] = RoyalUrDice.FOUR_BINARY;
        return map;
    }

    static readonly PARSING_MAP: Record<string, DiceFactory> = RoyalUrDice.createParsingMap();

    private readonly diceType: DiceType;
    private readonly delegate: Dice;

    constructor(diceType: DiceType) {
        super(diceType.getID());
        this.diceType = diceType;
        this.delegate = diceType.createDice();
    }

    private generateDie(up: boolean): RoyalUrRolledDie {
        return new RoyalUrRolledDie(
            up, rand(),
        );
    }

    generateRoll(value: number): RoyalUrRoll {
        let upCount;
        if (this.diceType === DiceType.FOUR_BINARY) {
            upCount = value;
        } else if (this.diceType === DiceType.THREE_BINARY_0EQ4) {
            upCount = (value === 4 ? 0 : value);
        } else {
            throw new Error(`Unsupported dice type ${this.diceType.getName()}`);
        }

        const dieCount = this.diceType.getDieCount();
        const dies = [];
        for (let index = 0; index < dieCount; ++index) {
            dies.push(this.generateDie(index < upCount));
        }
        randShuffle(dies);
        return new RoyalUrRoll(value, dies);
    }

    createRollFromDies(dies: RoyalUrRolledDie[]): RoyalUrRoll {
        let upCount = 0;
        for (const die of dies) {
            if (die.isUp()) {
                upCount += 1;
            }
        }

        let value;
        if (this.diceType === DiceType.FOUR_BINARY) {
            value = upCount;
        } else if (this.diceType === DiceType.THREE_BINARY_0EQ4) {
            value = (upCount === 0 ? 4 : upCount);
        } else {
            throw new Error(`Unsupported dice type ${this.diceType.getName()}`);
        }
        return new RoyalUrRoll(value, dies);
    }

    getMaxRollValue(): number {
        return this.delegate.getMaxRollValue();
    }

    getRollProbabilities(): number[] {
        return this.delegate.getRollProbabilities();
    }

    rollValue(): number {
        return this.delegate.rollValue();
    }

    static cast(dice: Dice): RoyalUrDice {
        if (!(dice instanceof RoyalUrDice))
            throw new Error("Dice is not a RoyalUrDice!");

        return dice;
    }

    static getByID(id: string): RoyalUrDiceFactory {
        for (const diceType of RoyalUrDice.values()) {
            if (diceType.getID() === id)
                return diceType;
        }
        throw new Error(`Unknown RoyalUr dice type ${id}`);
    }

    static getByIDOrNull(id: string | null): RoyalUrDiceFactory | null {
        if (id === null)
            return null;

        for (const diceType of RoyalUrDice.values()) {
            if (diceType.getID() === id)
                return diceType;
        }
        return null;
    }
}
