import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { GameType } from "@/ts/business/game/GameType";
import { GameMode } from "@/ts/business/game/GameMode";


export class LobbySummary {
    public readonly lobbyID: string;
    public readonly mode: GameMode;
    public readonly settings: GameType;
    public readonly player1: LobbyPlayer | null;
    public readonly player2: LobbyPlayer | null;

    constructor(
        lobbyID: string,
        mode: GameMode,
        settings: GameType,
        player1: LobbyPlayer | null,
        player2: LobbyPlayer | null,
    ) {
        this.lobbyID = lobbyID;
        this.mode = mode;
        this.settings = settings;
        this.player1 = player1;
        this.player2 = player2;
    }
}
