

export type UsageCategory = "free" | "memburs" | "special";


export class ReactionType {
    public static readonly WAVE = new ReactionType(
        "wave", "Wave", "👋", "free",
    );

    public static readonly CLAP = new ReactionType(
        "clap", "Clap", "👏", "free",
    );

    public static readonly HUG = new ReactionType(
        "hug", "Hug", "🤗", "free"
    );

    public static readonly COOL = new ReactionType(
        "cool", "Cool", "😎", "free",
    );

    public static readonly MELTING = new ReactionType(
        "melting", "Melting", "🫠", "free",
    );

    public static readonly SHOCKED = new ReactionType(
        "shocked", "Shocked", "😯", "free",
    );

    public static readonly CRY = new ReactionType(
        "cry", "Cry", "😭", "free"
    );

    public static readonly SPARKLE = new ReactionType(
        "sparkle", "Sparkle", "😅", "free"
    );

    public static readonly CRYSTAL_BALL = new ReactionType(
        "crystal_ball", "Crystal ball", "🔮", "memburs",
    );

    public static readonly DISORIENTED = new ReactionType(
        "disoriented", "Disoriented", "😵‍💫", "memburs",
    );

    public static readonly MIND_BLOWN = new ReactionType(
        "mind_blown", "Mind blown", "🤯", "memburs",
    );

    public static readonly TURTLE = new ReactionType(
        "turtle", "Turtle", "🐢", "memburs",
    );

    public static readonly HIDE = new ReactionType(
        "hide", "Hide", "", "memburs",
    );

    public static readonly THINK = new ReactionType(
        "think", "Think", "", "memburs",
    );

    public static readonly DETERMINED = new ReactionType(
        "determined", "Determined", "", "memburs",
    );

    public static readonly ROLL = new ReactionType(
        "roll", "Roll", "", "memburs",
    );

    public static readonly RESIGN = new ReactionType(
        "resign", "Resign", "", "special",
    );

    public static values(): ReactionType[] {
        return [
            ReactionType.WAVE,
            ReactionType.CLAP,
            ReactionType.HUG,
            ReactionType.SPARKLE,

            ReactionType.COOL,
            ReactionType.SHOCKED,
            ReactionType.CRY,
            ReactionType.MELTING,

            ReactionType.DISORIENTED,
            ReactionType.MIND_BLOWN,
            ReactionType.TURTLE,
            ReactionType.CRYSTAL_BALL,

            ReactionType.RESIGN,
        ];
    }

    private readonly id: string;
    private readonly name: string;
    private readonly text: string;
    private readonly category: UsageCategory;

    constructor(id: string, name: string, text: string, category: UsageCategory) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.category = category;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getText(): string {
        return this.text;
    }

    getCategory(): UsageCategory {
        return this.category;
    }

    static getByID(id: string): ReactionType {
        for (const diff of ReactionType.values()) {
            if (diff.getID() === id)
                return diff;
        }
        throw new Error(`Unknown computer difficulty ID: ${id}`);
    }

    static getByIDOrNull(id: string): ReactionType | null {
        for (const diff of ReactionType.values()) {
            if (diff.getID() === id)
                return diff;
        }
        return null;
    }
}
