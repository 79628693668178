import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonString } from "@/ts/util/json";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { MessageIn } from "@/ts/business/api/MessageIn";


export abstract class PacketIn extends MessageIn {
    public static readonly PACKET_TYPE_KEY = "packet_type";

    public readonly type: InboundPacketType;

    protected constructor(type: InboundPacketType) {
        super();
        this.type = type;
    }

    override read(context: MessageIOContext, json: Record<string, any>): void {
        const typeName = readJsonString(json, PacketIn.PACKET_TYPE_KEY);
        const type = InboundPacketType.getByName(typeName);
        if (type !== this.type) {
            throw new Error(
                "Incorrect packet type. Expected " + this.type.getName()
                + ", but received " + type.getName(),
            );
        }
        this.readContents(context, json);
    }

    abstract readContents(context: MessageIOContext, json: Record<string, any>): void;
}
