import { Game } from "@/ts/royalur/Game";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";


/**
 * The reason that a game was abandoned before it was finished.
 */
export class GameEndReason {
    public static readonly WIN = new GameEndReason(
        "win",
        "Win",
        "A player won the game",
        true,
    );

    public static readonly LEFT = new GameEndReason(
        "left",
        "Left",
        "A player left",
        true,
    );

    public static readonly LEFT_BEFORE_BEGIN = new GameEndReason(
        "left_b4_begin",
        "Left before game began",
        "A player left before starting the game",
        true,
    );

    public static readonly RESIGNATION = new GameEndReason(
        "resign",
        "Resignation",
        "A player resigned",
        true,
    );

    public static readonly SERVER = new GameEndReason(
        "server",
        "Server",
        "The server ended the game",
        false,
    );

    static values(): GameEndReason[] {
        return [
            GameEndReason.WIN,
            GameEndReason.LEFT,
            GameEndReason.LEFT_BEFORE_BEGIN,
            GameEndReason.RESIGNATION,
            GameEndReason.SERVER,
        ];
    }

    private readonly id: string;
    private readonly name: string;
    private readonly desc: string;
    private readonly isRequiresPlayer: boolean;

    constructor(
        id: string,
        name: string,
        desc: string,
        requiresPlayer: boolean,
    ) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.isRequiresPlayer = requiresPlayer;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getDesc(): string {
        return this.desc;
    }

    requiresPlayer(): boolean {
        return this.isRequiresPlayer;
    }

    static getByID(id: string): GameEndReason {
        for (const mode of GameEndReason.values()) {
            if (mode.id === id)
                return mode;
        }
        throw new Error("Unknown game end reason " + id);
    }

    static getByIDOrNull(id: string | null): GameEndReason | null {
        if (id === null)
            return null;

        for (const mode of GameEndReason.values()) {
            if (mode.id === id)
                return mode;
        }
        return null;
    }

    static infer(game: Game): {
        endReason: GameEndReason;
        endingPlayer: PlayerType | null;
    } {
        if (game.wasResigned()) {
            return {
                endReason: GameEndReason.RESIGNATION,
                endingPlayer: game.getResigningPlayer(),
            };
        }

        if (game.wasAbandoned()) {
            const abandonReason = game.getAbandonReason();
            if (abandonReason === AbandonReason.PLAYER_LEFT) {
                return {
                    endReason: (
                        game.getActionStates().length > 0
                            ? GameEndReason.LEFT
                            : GameEndReason.LEFT_BEFORE_BEGIN
                    ),
                    endingPlayer: game.getAbandoningPlayer(),
                };
            } else if (abandonReason === AbandonReason.EXTERNAL) {
                return {
                    endReason: GameEndReason.SERVER,
                    endingPlayer: null,
                };
            }
        }

        return {
            endReason: GameEndReason.WIN,
            endingPlayer: game.getWinner(),
        };
    }
}
