import { ErrorType } from "@/ts/business/game/error/ErrorType";


export abstract class ErrorData {
    private readonly type: ErrorType;

    protected constructor(type: ErrorType) {
        this.type = type;
    }

    getType(): ErrorType {
        return this.type;
    }

    abstract read(json: Record<string, any>): void;
}
