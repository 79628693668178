import { StateSource } from "@/ts/royalur/notation/StateSource";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Roll } from "@/ts/royalur/model/dice/Roll";
import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { EndGameState } from "@/ts/royalur/rules/state/EndGameState";
import { Move } from "@/ts/royalur/model/Move";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { ResignedGameState } from "@/ts/royalur/rules/state/ResignedGameState";
import { AbandonReason } from "@/ts/royalur/model/AbandonReason";
import { AbandonedGameState } from "@/ts/royalur/rules/state/AbandonedGameState";

/**
 * Produces game states from scratch.
 */
export class FullStateSource extends StateSource {
    private readonly board: Board;
    private readonly lightPlayer: PlayerState;
    private readonly darkPlayer: PlayerState;

    constructor(
        board: Board,
        lightPlayer: PlayerState,
        darkPlayer: PlayerState,
    ) {
        super();
        this.board = board;
        this.lightPlayer = lightPlayer;
        this.darkPlayer = darkPlayer;
    }

    private getPlayer(player: PlayerType): PlayerState {
        if (player === PlayerType.LIGHT)
            return this.lightPlayer;
        if (player === PlayerType.DARK)
            return this.darkPlayer;
        throw new Error(`Unknown player ${player.getName()}`);
    }

    override createRolledState(
        rules: RuleSet, turn: PlayerType, roll: Roll,
    ): RolledGameState {
        const availableMoves = rules.findAvailableMoves(
            this.board, this.getPlayer(turn), roll,
        );
        return new RolledGameState(
            this.board, this.lightPlayer, this.darkPlayer,
            turn, roll, availableMoves,
        );
    }

    override createMovedState(
        _rules: RuleSet, turn: PlayerType, roll: Roll, move: Move,
    ): MovedGameState {
        return new MovedGameState(
            this.board, this.lightPlayer, this.darkPlayer,
            turn, roll, move,
        );
    }

    override createWaitingForRollState(
        _rules: RuleSet, turn: PlayerType,
    ): WaitingForRollGameState {
        return new WaitingForRollGameState(
            this.board, this.lightPlayer, this.darkPlayer, turn,
        );
    }

    override createWaitingForMoveState(
        rules: RuleSet, turn: PlayerType, roll: Roll,
    ): WaitingForMoveGameState {
        const availableMoves = rules.findAvailableMoves(
            this.board, this.getPlayer(turn), roll,
        );
        return new WaitingForMoveGameState(
            this.board, this.lightPlayer, this.darkPlayer,
            turn, roll, availableMoves,
        );
    }

    override createResignedState(
        _rules: RuleSet, player: PlayerType,
    ): ResignedGameState {
        return new ResignedGameState(
            this.board, this.lightPlayer, this.darkPlayer, player,
        );
    }

    override createAbandonedState(
        _rules: RuleSet, reason: AbandonReason, player: PlayerType | null,
    ): AbandonedGameState {
        return new AbandonedGameState(
            this.board, this.lightPlayer, this.darkPlayer, reason, player,
        );
    }

    override createEndState(
        _rules: RuleSet, winner: PlayerType | null,
    ): EndGameState {
        return new EndGameState(
            this.board, this.lightPlayer, this.darkPlayer, winner,
        );
    }
}
