import { ErrorType } from "@/ts/business/game/error/ErrorType";
import { ErrorData } from "@/ts/business/game/error/ErrorData";
import { readJsonDate, readNullableJsonString } from "@/ts/util/json";


export class ExceededPandaLimitError extends ErrorData {
    private availableAgainAt: Date | null = null;
    private ongoingLobbyID: string | null = null;

    constructor() {
        super(ErrorType.EXCEEDED_PANDA_LIMIT);
    }

    getAvailableAgainAt(): Date {
        if (this.availableAgainAt === null)
            throw new Error("Packet has not been read yet");
        return this.availableAgainAt;
    }

    getOngoingLobbyID(): string | null {
        return this.ongoingLobbyID;
    }

    read(json: Record<string, any>): void {
        this.availableAgainAt = readJsonDate(json, "available_again_at");
        this.ongoingLobbyID = readNullableJsonString(json, "ongoing_lobby_id");
    }
}
