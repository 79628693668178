import { DiceFactory } from "@/ts/royalur/model/dice/DiceFactory";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { RoyalUrDice } from "@/ts/business/game/royalur/RoyalUrDice";
import { DiceType } from "@/ts/royalur/model/dice/DiceType";


/**
 * A factory for producing RoyalUrDice.
 */
export class RoyalUrDiceFactory implements DiceFactory {

    private readonly diceType: DiceType;

    constructor(diceType: DiceType) {
        this.diceType = diceType;
    }

    getID() {
        return this.diceType.getID();
    }

    getName(): string {
        return this.diceType.getName();
    }

    getDesc(): string {
        return this.diceType.getDesc();
    }

    getType(): DiceType {
        return this.diceType;
    }

    createDice(): RoyalUrDice {
        return new RoyalUrDice(this.diceType);
    }

    createRoll(value: number): RoyalUrRoll {
        return this.createDice().generateRoll(value);
    }

    isEquivalent(other: DiceFactory): boolean {
        if (!(other instanceof RoyalUrDiceFactory))
            return false;

        return this.diceType === other.diceType;
    }
}
