import { Game } from "@/ts/royalur/Game";


/**
 * A notation can be used as a shared format for distributing
 * games of the Royal Game of Ur. Notations can be used to encode
 * games into text, and to decode games from text. They are not
 * guaranteed to be lossless in their conversion. Therefore, you
 * should check the specific notation you are considering to
 * determine the information that it is able to save.
 */
export abstract class Notation {
    abstract encodeGame(game: Game): string;

    abstract decodeGame(encoded: string): Game;
}
