import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonString, readNullableJsonDict } from "@/ts/util/json";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { ErrorType } from "@/ts/business/game/error/ErrorType";
import { ErrorData } from "@/ts/business/game/error/ErrorData";
import { OutdatedClientError } from "@/ts/business/game/error/OutdatedClientError";
import { MustSignInError } from "@/ts/business/game/error/MustSignInError";
import { RequiresMemburshipError } from "@/ts/business/game/error/RequiresMemburshipError";
import { ExceededPandaLimitError } from "@/ts/business/game/error/ExceededPandaLimitError";


export class PacketInError extends PacketIn {
    public errorType: ErrorType = ErrorType.UNKNOWN;
    public message: string | null = null;
    public data: ErrorData | null = null;

    constructor() {
        super(InboundPacketType.ERROR);
    }

    getMessage(): string {
        if (this.message === null)
            throw new Error("Packet has not been read");
        return this.message;
    }

    getData(): ErrorData | null {
        return this.data;
    }

    static createErrorData(errorType: ErrorType): ErrorData | null {
        if (errorType === ErrorType.UNKNOWN)
            return null;
        if (errorType === ErrorType.OUTDATED_CLIENT)
            return new OutdatedClientError();
        if (errorType === ErrorType.MUST_SIGN_IN)
            return new MustSignInError();
        if (errorType === ErrorType.REQUIRES_MEMBURSHIP)
            return new RequiresMemburshipError();
        if (errorType === ErrorType.EXCEEDED_PANDA_LIMIT)
            return new ExceededPandaLimitError();
        throw new Error(`Unknown error type ${errorType.getID()}`);
    }

    static readErrorData(errorType: ErrorType, dataJSON: Record<string, any>): ErrorData | null {
        const data = PacketInError.createErrorData(errorType);
        if (!data)
            return null;

        data.read(dataJSON);
        return data;
    }

    override readContents(
        _context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        this.errorType = ErrorType.getByID(readJsonString(json, "error_type"));
        this.message = readJsonString(json, "message");

        const dataJSON = readNullableJsonDict(json, "data");
        if (dataJSON !== null) {
            this.data = PacketInError.readErrorData(this.errorType, dataJSON);
        } else {
            this.data = null;
        }
    }
}
