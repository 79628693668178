import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonString } from "@/ts/util/json";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketInSetID extends PacketIn {
    public static readonly ID_KEY = "id";

    public id: string | null = null;

    constructor() {
        super(InboundPacketType.SET_ID);
    }

    override readContents(_context: MessageIOContext, json: Record<string, any>): void {
        this.id = readJsonString(json, PacketInSetID.ID_KEY);
    }
}
