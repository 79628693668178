import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonString } from "@/ts/util/json";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketInJoinLobby extends PacketIn {
    public static readonly LOBBY_ID_KEY = "lobby_id";

    public lobbyID: string | null = null;

    constructor() {
        super(InboundPacketType.JOIN_LOBBY);
    }

    override readContents(
        _context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        this.lobbyID = readJsonString(json, PacketInJoinLobby.LOBBY_ID_KEY);
    }
}
