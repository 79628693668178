import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { GameMode } from "@/ts/business/game/GameMode";
import {
    readJsonNumber,
    readJsonString,
    readNullableJsonInt,
    readNullableJsonString,
} from "@/ts/util/json";
import { castNullablePlayerNo, PlayerNo } from "@/ts/business/game/PlayerNo";
import { BotType } from "@/ts/business/game/BotType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


export class PacketInLobbyStatus extends PacketIn {
    public gameMode: GameMode | null = null;
    public botType: BotType | null = null;
    public endReason: GameEndReason | null = null;
    public endingPlayer: PlayerType | null = null;
    public player1LastPingTimeMs: number | null = null;
    public player2LastPingTimeMs: number | null = null;
    public finishedGameID: string | null = null;
    public rematchRequestedBy: PlayerNo | null = null;

    constructor() {
        super(InboundPacketType.LOBBY_STATUS);
    }

    override readContents(
        _context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        const gameModeName = readJsonString(json, "mode");
        this.gameMode = GameMode.getByName(gameModeName);
        this.botType = BotType.getByIDOrNull(readNullableJsonString(json, "bot_type"));
        const endReasonID = readNullableJsonString(json, "end_reason");
        this.endReason = GameEndReason.getByIDOrNull(endReasonID);
        const endingPlayerCh = readNullableJsonString(json, "ending_player");
        this.endingPlayer = PlayerType.getByCharOrNull(endingPlayerCh);
        this.player1LastPingTimeMs = readJsonNumber(json, "player1_last_ping_time_ms");
        this.player2LastPingTimeMs = readJsonNumber(json, "player2_last_ping_time_ms");
        this.finishedGameID = readNullableJsonString(json, "finished_game_id");
        this.rematchRequestedBy = castNullablePlayerNo(
            readNullableJsonInt(json, "rematch_requested_by"),
        );
    }
}
