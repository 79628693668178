

export class TimeControlState {
    static readonly EMPTY: TimeControlState = new TimeControlState(0, 0, 0);

    public readonly rollExpiryTimeMs: number;
    public readonly moveExpiryTimeMs: number;
    public readonly gameAbandonTimeMs: number;
    private readonly firstTimeMs: number;

    constructor(rollExpiryTimeMs: number, moveExpiryTimeMs: number, gameAbandonTimeMs: number) {
        this.rollExpiryTimeMs = rollExpiryTimeMs;
        this.moveExpiryTimeMs = moveExpiryTimeMs;
        this.gameAbandonTimeMs = gameAbandonTimeMs;

        const all = [rollExpiryTimeMs, moveExpiryTimeMs, gameAbandonTimeMs];
        let firstTimeMs: number = 0;
        for (const timeMs of all) {
            if (timeMs !== 0 && (firstTimeMs === 0 || timeMs < firstTimeMs)) {
                firstTimeMs = timeMs;
            }
        }
        this.firstTimeMs = firstTimeMs;
    }

    isEmpty() {
        return (
            this.rollExpiryTimeMs === 0
            && this.moveExpiryTimeMs === 0
            && this.gameAbandonTimeMs === 0
        );
    }

    getFirstTimeMs(): number {
        return this.firstTimeMs;
    }
}

