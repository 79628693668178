import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonDict, readNullableJsonChar } from "@/ts/util/json";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";


export class PacketInPlayerData extends PacketIn {
    public yourPlayer: PlayerType | null = null;
    public player1: LobbyPlayer | null = null;
    public player2: LobbyPlayer | null = null;

    constructor() {
        super(InboundPacketType.PLAYER_DATA);
    }

    getPlayer(playerType: PlayerType): LobbyPlayer {
        if (this.player1 === null || this.player2 === null)
            throw new Error("Packet has not been read");

        const data1 = this.player1.getGameData();
        return data1?.getPlayerType() === playerType ? this.player1 : this.player2;
    }

    override readContents(
        context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        const yourPlayerChar = readNullableJsonChar(json, "your_player");
        this.yourPlayer = (yourPlayerChar !== null ? PlayerType.getByChar(yourPlayerChar) : null);

        const lobbyNotation = context.getLobbyNotation();
        this.player1 = lobbyNotation.readLobbyPlayer(readJsonDict(json, "player1"));
        this.player2 = lobbyNotation.readLobbyPlayer(readJsonDict(json, "player2"));
    }
}
